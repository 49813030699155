import { Modal } from 'react-bootstrap'
import { ReactComponent as WorldPrimary } from '../../../assets/world-primary.svg'
import { usePaeseContext } from '../../../context/PaeseContext'
import styles from './ModalPaese.module.css'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { useTopContextState } from '../../../context/TopContext'
import classNames from 'classnames'
import { unicodeToChar } from '../../../utils'
import { useNavigate } from 'react-router-dom'

const getFilters = (params: URLSearchParams) => ({
  search: params.get('search') ?? '',
  municipality: params.get('municipality') ?? '',
  ordering: params.get('ordering') ?? '',
  in_contest: params.get('in_contest') ?? 'true',
  only_without_pictures: params.get('only_without_pictures') ?? '',
  app_category: params.get('app_category') ?? '',
  user_lat: params.get('user_lat') ?? '',
  user_lon: params.get('user_lon') ?? '',
  monument_lon: Number(params.get('monument_lon')) ?? '',
  monument_lat: Number(params.get('monument_lat')) ?? '',
  monument_id: Number(params.get('monument_id')) ?? '',
  cluster_active: params.get('cluster_active') ?? 'true',
  geo_context: params.get('geo_context') ?? '',
})

export default function ModalPaese({
  setModalPaese,
  modalPaese,
}: {
  modalPaese: boolean
  setModalPaese: (modalPaese: boolean) => void
}) {
  const { country, setCountry } = usePaeseContext()
  const [countrySelected, setCountrySelected] = useState(country)
  const { t, i18n } = useTranslation()
  const { geocontexts } = useTopContextState()
  const navigate = useNavigate()
  return (
    <Modal show={modalPaese} onHide={() => setModalPaese(false)} centered>
      <Modal.Body className={styles.ModalBody}>
        <div className={styles.TitleModal}>
          <WorldPrimary className="me-2" /> {t('SELECT_COUNTRY')}
        </div>
        <div className={styles.DescriptionModal}>
          {t('SELECT_COUNTRY_TEXT')}
        </div>
        <div className={styles.ListOrderingItems}>
          {geocontexts.length > 0 &&
            geocontexts.map((geocontext) => (
              <div
                key={geocontext.id}
                className={classNames(styles.OrderingItem, {
                  [styles.OrderingItemActive]:
                    countrySelected.id === geocontext.id,
                })}
                onClick={() => {
                  setCountrySelected({
                    id: geocontext.id,
                    label: geocontext.label,
                  })
                  localStorage.setItem(
                    'trigger_paese',
                    JSON.stringify({
                      id: geocontext.id,
                      label: geocontext.label,
                    })
                  )
                }}
              >
                <div className={styles.OrderingItemTitle}>
                  <span className="me-2">
                    {unicodeToChar(geocontext?.flag)}
                  </span>
                  {geocontext.label}
                  <div>
                    <span className={styles.LabelInfo}>{t('MONUMENTS')}:</span>
                    <span className={styles.ValueInfo}>
                      {geocontext.num_monuments || 0}
                    </span>
                  </div>
                  <div>
                    <span className={styles.LabelInfo}>
                      {t('NEXT_CONTEST')}:
                    </span>
                    <span className={styles.ValueInfo}>
                      {geocontext.next_contest?.label}
                    </span>
                  </div>
                  {geocontext.next_contest?.active && (
                    <div className="d-flex align-items-center mt-1">
                      <div
                        style={{
                          height: 10,
                          width: 10,
                          background: '#00C54F',
                          borderRadius: '50%',
                        }}
                        className="me-1"
                      ></div>
                      <span className={styles.ValueInfo}>
                        {t('ACTIVE_CONTEST')}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            ))}
        </div>
        <div className="mt-4">
          <button
            className={styles.ButtonConfirm}
            onClick={() => {
              setCountry(countrySelected)
              localStorage.setItem(
                'paese',
                JSON.stringify({
                  id: countrySelected.id,
                  label: countrySelected.label,
                })
              )

              setModalPaese(false)
              if (location.pathname.indexOf('lista') > -1) {
                navigate(
                  `/${i18n.language}/lista?geo_context=${countrySelected.id}&ordering=distance&user_lat=${getFilters(
                    new URLSearchParams(location.search)
                  ).user_lat}&user_lon=${getFilters(
                    new URLSearchParams(location.search)
                  ).user_lon}`
                )
              } else {
                navigate(
                  `/${i18n.language}/mappa?geo_context=${countrySelected.id}&ordering=distance&user_lat=${getFilters(
                    new URLSearchParams(location.search)
                  ).user_lat}&user_lon=${getFilters(
                    new URLSearchParams(location.search)
                  ).user_lon}`
                )
              }
            }}
          >
            {t('CONTINUE')}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}
