import styles from './Topbar.module.css'
import { ReactComponent as Logo } from '../../../assets/wiki-logo-mobile.svg'
import { ReactComponent as Bell } from '../../../assets/bell.svg'
import { ReactComponent as Burger } from '../../../assets/burger.svg'
import { useMemo } from 'react'
import WikiLoveMonuments from '../WikiLoveMonuments'
import { useTranslation } from 'react-i18next'
import { SlidesPresentazioneMobile } from '../Layout'
import ModalLanguage from '../../Desktop/ModalLanguage'
import ModalPaese from '../../Desktop/ModalPaese'
import { getActiveGeocontext, unicodeToChar } from '../../../utils'
import { useNavigate } from 'react-router-dom'

export default function Topbar({
  show,
  setShow,
  infoWiki,
  setInfoWiki,
  presentazione,
  setPresentazione,
  modalPaese,
  setModalPaese,
  modalLang,
  setModalLang,
}: {
  show: boolean
  setShow: (value: boolean) => void
  infoWiki: boolean
  setInfoWiki: (value: boolean) => void
  presentazione: boolean
  setPresentazione: (value: boolean) => void
  modalPaese: boolean
  setModalPaese: (value: boolean) => void
  modalLang: boolean
  setModalLang: (value: boolean) => void
}) {
  
  const { t } = useTranslation()

  const activeGeocontext = getActiveGeocontext()
  
  const activeContest = useMemo(() => {
    if (!activeGeocontext) return null
    if (!activeGeocontext.next_contest) return null
    if (activeGeocontext.next_contest.active) return activeGeocontext.next_contest
    return null
  }, [activeGeocontext])

  const navigate = useNavigate()

  return (
    <>
      <div className={styles.TopBar}>
        <div
          className="pointer"
          onClick={() => {
            navigate('/')
          }}
        >
          <Logo />
        </div>
        {activeContest && (
          <div className={styles.ContainerTitle}>
            <Bell /> <span className={styles.Title}>{t('CONTEST')} WLM</span>
          </div>
        )}
        <div className='d-flex align-items-center'>
          <div
            className="pointer me-3"
            style={{ fontSize: '1.5rem' }}
            onClick={() => {
              setModalPaese(true)
            }}>
              {activeGeocontext && activeGeocontext.flag && unicodeToChar(
                activeGeocontext.flag
              )}
            </div>
          <Burger
            className="pointer"
            onClick={() => {
              // blur body when menu is open backdrop-filter: blur(10px);

              setShow(!show)
            }}
          />
        </div>
      </div>
      <ModalLanguage modalLang={modalLang} setModalLang={setModalLang} />
      <ModalPaese modalPaese={modalPaese} setModalPaese={setModalPaese} />
      <WikiLoveMonuments
        infoWiki={infoWiki}
        setPresentazione={setPresentazione}
        setInfoWiki={(infoWiki: boolean) => setInfoWiki(infoWiki)}
      />
      {presentazione && (
        <SlidesPresentazioneMobile
          isFromPageWiki
          setPresentazione={setPresentazione}
        />
      )}
    </>
  )
}
