import Layout from '../../../components/Desktop/Layout'
import { ReactComponent as WorldPrimary } from '../../../assets/world-primary.svg'
import styles from './Home.module.css'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { usePaeseContext } from '../../../context/PaeseContext'
import { useTopContextState } from '../../../context/TopContext'
import { unicodeToChar } from '../../../utils'
import { useEffect } from 'react'

export default function Home() {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  const { setCountry } = usePaeseContext()

  const { geocontexts } = useTopContextState()

  useEffect(() => {
    if(localStorage.getItem('paese')) {
      // redirect to mappa
      const paese = JSON.parse(localStorage.getItem('paese') || '{}')
      navigate(`/${i18n.language}/mappa?geo_context=${paese.id}`)
    }
  }, [navigate, i18n.language])

  return (
    <Layout>
      <div className="w-100 h-100 d-flex justify-content-center position-relative">
        <div
          className={styles.HomeContent}
          style={{
            width: 532,
            transition: 'width 0.3s ease-in-out',
          }}
        >
          <div className={styles.Title}>
            <WorldPrimary className="me-2" /> {t('SELECT_COUNTRY')}
          </div>
          <div className={styles.Desc}>{t('SELECT_COUNTRY_TEXT')}</div>
          <div className="mt-4 w-100">
            {geocontexts.length > 0 &&
              geocontexts.map((geocontext) => (
                <div
                  key={geocontext.id}
                  className={styles.CardPaese}
                  onClick={() => {
                    setCountry({
                      id: geocontext.id,
                      label: geocontext.label,
                    })
                    localStorage.setItem(
                      'paese',
                      JSON.stringify({
                        id: geocontext.id,
                        label: geocontext.label,
                      })
                    )
                    localStorage.setItem(
                      'trigger_paese',
                      JSON.stringify({
                        id: geocontext.id,
                        label: geocontext.label,
                      })
                    )
                    navigate(`/${i18n.language}/mappa?geo_context=${geocontext.id}`)
                  }}
                >
                  <div className={styles.CardPaeseTitle}>
                    <span style={{ fontSize: 25 }} className="me-2">
                      {unicodeToChar(geocontext?.flag)}
                    </span>
                    {geocontext.label}
                  </div>
                  <div>
                    <span className={styles.LabelInfo}>
                      {t('MONUMENTS')}:
                    </span>
                    <span className={styles.ValueInfo}>
                      {geocontext.num_monuments || 0}
                    </span>
                  </div>
                  <div>
                  <span className={styles.LabelInfo}>
                      {t('NEXT_CONTEST')}:
                    </span>
                    <span className={styles.ValueInfo}>
                      {geocontext.next_contest?.label}
                    </span>
                  </div>
                  {geocontext.next_contest?.active && (
                    <div className="d-flex align-items-center mt-1">
                      <div style={{
                        height: 10,
                        width: 10,
                        background: '#00C54F',
                        borderRadius: '50%',
                      }}
                      className="me-1">
                        
                      </div>
                      <span className={styles.ValueInfo}>
                        {t('ACTIVE_CONTEST')}
                      </span>
                      
                    </div>
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}
