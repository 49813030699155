import { useQuery, useMutation } from '@tanstack/react-query'
import { API_URL } from '../const'
import axios from 'axios'
import { Geocontext, ScrapingGeocontext } from '../types'
import { useAuthUser } from 'use-eazy-auth'

export async function getGeocontexts(
  signal?: AbortSignal
) {
  return (
    await axios.get(`${API_URL}/geo-contexts/`, {
      signal,
    })
  ).data as Geocontext[]
}

export function useGeocontexts() {
  return useQuery(
    ['geo-contexts'],
    ({ signal }) => getGeocontexts(signal),
    {
      keepPreviousData: true,
      suspense: false,
    }
  )
}

export async function getLastScrapingGeocontext(id: number) {
  return (
    await axios.get(`${API_URL}/geo-contexts/${id}/scraping/`)
  ).data as ScrapingGeocontext
}

export function useLastScrapingGeocontext(id: number) {
  return useQuery(
    ['geo-contexts', id, 'scraping'],
    () => getLastScrapingGeocontext(id),
    {
      keepPreviousData: true,
      suspense: false,
      enabled: !!id,
    }
  )
}


export async function startScraping(id: number, token: string, performCommons: boolean) {
  return (
    await axios.post(`${API_URL}/geo-contexts/${id}/start-snapshot/`, {
      perform_commons: performCommons,
    }, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
  )
}


export function useStartScraping(id: number) {
  const { token } = useAuthUser()
  return useMutation({
    mutationFn: (performCommons:boolean) => startScraping(id, token!, performCommons),

  })
  
}

export async function stopScraping(id: number, token: string) {
  return (
    await axios.post(`${API_URL}/geo-contexts/${id}/stop-snapshot/`, null, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    })
  )
}

export function useStopScraping(id: number) {
  const { token } = useAuthUser()
  return useMutation({
    mutationFn: () => stopScraping(id, token!),

  })
  
}