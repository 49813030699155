import { ReactComponent as Legenda } from '../../../assets/legend-primary.svg'
import { ReactComponent as Close } from '../../../assets/close.svg'
import { ReactComponent as MonumentoNoFoto } from '../../../assets/monumento-no-foto.svg'
import { ReactComponent as Monumento1a10 } from '../../../assets/monumento-1a10.svg'
import { ReactComponent as MonumentoMaggiore10 } from '../../../assets/monumento-maggiore-10.svg'
import { ReactComponent as MonumentoConcorso } from '../../../assets/concorso.svg'
import { ReactComponent as MonumentoNoConcorso } from '../../../assets/no-concorso.svg'
import styles from './Legend.module.css'
import { useTranslation } from 'react-i18next'
import { getActiveGeocontext } from '../../../utils'

interface Props {
  legend: boolean
  setLegend: (legend: boolean) => void
}

export default function Legend({ legend, setLegend }: Props) {
  const { t } = useTranslation()
  const activeGeocontext = getActiveGeocontext()
  return (
    <div className={styles.Legend}>
      <div className={styles.TopLegend}>
        <div className={styles.LegendaLabel}>
          <Legenda fill="var(--primary)" />
          <span className="ms-2">{t('LEGEND')}</span>
        </div>
        <div className="pointer">
          <Close onClick={() => setLegend(false)} />
        </div>
      </div>
      <div className={styles.CardCategorie}>
        <div className={styles.TitleCategorie}>
          {t('CATEGORIES_OF_MONUMENTS')}
        </div>
        <div className={styles.DescCategorie}>
          {t('CATEGORIES_OF_MONUMENTS_TEXT')}
        </div>
        {activeGeocontext?.app_categories.map((category) => (
          <div key={category.id} className={styles.ItemCard}>
            <img
              src={category.icon.icon_color}
              alt={category.name}
              width={13}
              height={13}
            />
            <span className={styles.NameItem}>{category.name}</span>
          </div>
        ))}
      </div>
      <div className={styles.CardColori}>
        <div className={styles.TitleCategorie}>
          {t('HOW_TO_READ_THE_COLORS')}
        </div>
        <div className={styles.DescCategorie}>
          {t('HOW_TO_READ_THE_COLORS_TEXT')}
        </div>
        <div className={styles.ItemCard}>
          <MonumentoNoFoto />
          <span className={styles.NameItem}>
            {t('THE_MONUMENT_HAS_NO_PHOTO')}
          </span>
        </div>
        <div className={styles.ItemCard}>
          <Monumento1a10 />
          <span className={styles.NameItem}>
            {t('THE_MONUMENT_HAS_FROM_1_TO_10_PHOTOS')}
          </span>
        </div>
        <div className={styles.ItemCard}>
          <MonumentoMaggiore10 />
          <span className={styles.NameItem}>
            {t('THE_MONUMENT_HAS_MORE_THAN_10_PHOTOS')}
          </span>
        </div>
      </div>
      <div className={styles.CardConcorso}>
        <div className={styles.DescCategorie}>
          {t('COLOR_ICON_MONUMENT_TEXT')}
        </div>
        <div className={styles.ItemCard}>
          <MonumentoConcorso />
          <span className={styles.NameItem}>
            {t('THE_MONUMENT_IS_IN_CONTEST')}
          </span>
        </div>
        <div className={styles.ItemCard}>
          <MonumentoNoConcorso />
          <span className={styles.NameItem}>
            {t('THE_MONUMENT_IS_NOT_IN_CONTEST')}
          </span>
        </div>
      </div>
    </div>
  )
}
