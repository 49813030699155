import styles from './BlockFilters.module.css'
import { ReactComponent as FiltersIcon } from '../../../assets/filter-primary.svg'
import { ReactComponent as CheckOrderingIcon } from '../../../assets/ordering-checked.svg'
import { ReactComponent as UncheckOrderingIcon } from '../../../assets/ordering-unchecked.svg'
import { ReactComponent as CloseSecondary } from '../../../assets/close-secondary.svg'
import { ReactComponent as MyLocation } from '../../../assets/my-location.svg'
import { ReactComponent as Flag } from '../../../assets/flag.svg'
import { ReactComponent as ExpandLess } from '../../../assets/expand-less.svg'
import { useTranslation } from 'react-i18next'
import { useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import ReactSwitch from 'react-switch'
import { useComuni } from '../../../hooks/comuni'
import { useDetectClickOutside } from 'react-detect-click-outside'
import { getActiveGeocontext } from '../../../utils'
import { useAuthUser } from 'use-eazy-auth'
import { useLocation } from 'react-router-dom'

interface Props {
  setFilters: (filters: any) => void
  filters: Record<string, any>
  setDetail: (detail: any) => void
  setComuneFilterCoords?: (coords: number[]) => void
}

export default function BlockFilters({
  setFilters,
  filters,
  setDetail,
  setComuneFilterCoords,
}: Props) {
  const { t } = useTranslation()
  const [searchComune, setSearchComune] = useState<string>('')
  const [openOptions, setOpenOptions] = useState<boolean>(false)
  const [openCategory, setOpenCategory] = useState<boolean>(false)

  const activeGeocontext = getActiveGeocontext()

  const filtersComuni = useMemo(() => {
    if (searchComune !== '') {
      return {
        q: searchComune,
      }
    } else {
      if (filters.municipality !== '') {
        return {
          q: searchComune,
          id: filters.municipality,
        }
      } else {
        return {}
      }
    }
  }, [searchComune, filters.municipality])

  const { data: comuni } = useComuni(activeGeocontext?.id, filtersComuni)

  const comuniFiltered = useMemo(() => {
    const searchTrimmed = searchComune.trimStart().trimEnd()
    if (searchTrimmed === '') {
      return []
    }
    const comuniFiltered = comuni?.filter((comune) =>
      comune.name.toLowerCase().includes(searchTrimmed.toLowerCase())
    )
    const comuniOrdered = comuniFiltered?.sort((a, b) => {
      const aStartWith = a.name.toLowerCase().startsWith(searchTrimmed)
      const bStartWith = b.name.toLowerCase().startsWith(searchTrimmed)
      if (aStartWith && !bStartWith) {
        return -1
      } else if (!aStartWith && bStartWith) {
        return 1
      } else {
        return a.name.length - b.name.length
      }
    })

    return comuniOrdered
  }, [searchComune, comuni])

  useEffect(() => {
    if (filters.municipality) {
      const comune = comuni?.find(
        (comune) => String(comune.id) === String(filters.municipality)
      )
      setSearchComune(comune?.name || '')
    }
  }, [filters.municipality, comuni])

  useEffect(() => {
    if (filters.municipality === '') {
      setSearchComune('')
    }
  }, [filters.municipality])

  const refCategory = useDetectClickOutside({
    onTriggered: () => {
      setOpenCategory(false)
    },
  })

  const orderedCategories = useMemo(() => {
    if (activeGeocontext) {
      if (
        !activeGeocontext.app_categories ||
        activeGeocontext.app_categories.length === 0
      ) {
        return []
      }
      return activeGeocontext.app_categories.sort((a, b) =>
        a.order > b.order ? 1 : -1
      )
    }
    return []
  }, [activeGeocontext])

  const { user } = useAuthUser()
  const username = user ? user.username.replace('mw--', ' ') : ''

  const location = useLocation()

  return (
    <div className={styles.BlockFilters}>
      <div className={styles.TopFiltri}>
        <div className="d-flex align-items-center">
          <FiltersIcon />{' '}
          <div className={styles.FilterLabelFiltri}>{t('FILTERS')}</div>
        </div>
        <div
          className={classNames({
            [styles.ResetButton]:
              filters.app_category !== '' ||
              filters.municipality !== '' ||
              filters.in_contest !== 'true' ||
              filters.only_without_pictures !== '' ||
              filters.without_coordinates !== '',
            [styles.ResetButtonDisabled]:
              filters.app_category === '' &&
              filters.municipality === '' &&
              filters.in_contest === 'true' &&
              filters.only_without_pictures === '' ||
              filters.without_coordinates === '',
          })}
          onClick={() => {
            if (
              filters.app_category !== '' ||
              filters.municipality !== '' ||
              filters.only_without_pictures !== '' ||
              filters.in_contest !== 'true' ||
              filters.without_coordinates !== ''
            ) {
              setFilters({
                app_category: '',
                municipality: '',
                in_contest: 'true',
                only_without_pictures: '',
                without_coordinates: '',
              })
              setSearchComune('')
            }
          }}
        >
          {t('RESET')}
        </div>
      </div>
      <div className={styles.FilterComune}>
        <div className={styles.FilterLabel}>{t('MUNICIPALITY')}</div>
        <div className="w-100 position-relative">
          <input
            className={styles.InputComune}
            type="text"
            placeholder={t('SEARCH_MUNICIPALITY')}
            value={searchComune || ''}
            onChange={(e) => {
              setOpenOptions(true)
              setSearchComune(e.target.value)
            }}
          />
          {filters.municipality !== '' && (
            <div
              title={t('CENTER_MUNICIPALITY')}
              className={styles.PointComune}
              onClick={() => {
                const comune = comuni?.find(
                  (comune) =>
                    comune.name.toLowerCase() === searchComune.toLowerCase()
                )
                if (comune && setComuneFilterCoords) {
                  setComuneFilterCoords(comune.centroid.coordinates)
                }
                setOpenOptions(false)
              }}
            >
              <MyLocation />
            </div>
          )}
          {searchComune.length > 0 && (
            <div
              className={styles.CloseIconWrapper}
              onClick={(e) => {
                setSearchComune('')
                setFilters({
                  ...filters,
                  municipality: '',
                })

                setOpenOptions(false)
              }}
            >
              <CloseSecondary className={styles.CloseIcon} />
            </div>
          )}
        </div>
        {openOptions &&
          searchComune.length > 0 &&
          comuniFiltered &&
          comuniFiltered?.length > 0 && (
            <div className={styles.ComuniList}>
              {comuniFiltered.map((comune) => (
                <div
                  key={comune.code}
                  className={classNames(styles.ComuneItem)}
                  onClick={() => {
                    setDetail(null)
                    setSearchComune(comune.name)
                    setOpenOptions(false)
                    setFilters({
                      ...filters,
                      municipality: comune.id,
                      monument_id: '',
                      monument_lat: '',
                      monument_lon: '',
                    })
                    if (setComuneFilterCoords) {
                      const coords = comune.centroid.coordinates
                      setComuneFilterCoords(coords)
                    }
                    // sessionStorage.removeItem('monument_id')
                  }}
                >
                  <div className={styles.ComuneItemTitle}>
                    <Flag className="me-2" /> {comune.name}
                  </div>
                </div>
              ))}
            </div>
          )}
      </div>

      <div className={styles.FilterCategoria} ref={refCategory}>
        <div className={styles.FilterLabel}>{t('CATEGORY')}</div>
        <div className={'w-100 position-relative'}>
          {/*  select with options */}
          <div
            className={styles.SelectCategoria}
            onClick={() => setOpenCategory(!openCategory)}
          >
            {filters.app_category === '' ? (
              <div className={styles.SelectCategoriaPlaceholder}>
                {t('SELECT_A_CATEGORY')}
              </div>
            ) : (
              <div className={styles.SelectCategoriaText}>
                {t(
                  activeGeocontext?.app_categories.find(
                    (category) =>
                      String(category.id) === String(filters.app_category)
                  )?.name || ''
                )}
              </div>
            )}
            {filters.app_category !== '' && (
              <div
                className={styles.CloseIconWrapper}
                onClick={(e) => {
                  e.stopPropagation()
                  e.preventDefault()
                  setFilters({
                    ...filters,
                    app_category: '',
                  })
                  setOpenCategory(false)
                }}
              >
                <CloseSecondary className={styles.CloseIcon} />
              </div>
            )}
            {openCategory ? (
              <ExpandLess className={styles.ExpandIcon} />
            ) : (
              <ExpandLess
                style={{
                  transform: 'translateY(-50%) rotate(180deg)',
                }}
                className={styles.ExpandIcon}
              />
            )}
          </div>
          {openCategory && (
            <div className={styles.OptionsCategoria}>
              {orderedCategories.length > 0 &&
                orderedCategories?.map((category) => (
                  <div
                    key={category.id}
                    className={classNames(styles.FilterItem, {
                      [styles.FilterItemActive]:
                        String(filters.app_category) === String(category.id),
                    })}
                    onClick={() => {
                      setDetail(null)
                      setFilters({
                        ...filters,
                        app_category: category.id,
                      })
                      setOpenCategory(false)
                    }}
                  >
                    <div>
                      {filters.app_category === category.id ? (
                        <CheckOrderingIcon />
                      ) : (
                        <UncheckOrderingIcon />
                      )}
                    </div>
                    <div className={styles.FilterItemTitle}>
                      {filters.app_category === category.id ? (
                        <img
                          src={category.icon.icon_color}
                          alt={category.name}
                          width="13"
                          height="13"
                        />
                      ) : (
                        <img
                          src={category.icon.icon_color}
                          alt={category.name}
                          width="13"
                          height="13"
                        />
                      )}
                      <span className="ms-2">{t(category.name)}</span>
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
      <div className={styles.Filter}>
        <div className={styles.FilterTitle}>
          {t('ALSO_MONUMENTS_OUT_OF_CONTEST')}
        </div>
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center">
            <ReactSwitch
              offColor="#D0DFE4"
              size={15}
              checkedIcon={false}
              uncheckedIcon={false}
              onColor="#40BAEC"
              onChange={(checked) => {
                setFilters({
                  ...filters,
                  in_contest: checked ? '' : 'true',
                })
              }}
              checked={filters.in_contest !== 'true'}
            />
          </div>
        </div>
      </div>
      <div className={styles.Filter}>
        <div className={styles.FilterTitle}>
          {t('ONLY_MONUMENTS_WITHOUT_PHOTOS')}
        </div>
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center">
            <ReactSwitch
              offColor="#D0DFE4"
              size={15}
              checkedIcon={false}
              uncheckedIcon={false}
              onColor="#40BAEC"
              onChange={(checked) => {
                setFilters({
                  ...filters,
                  only_without_pictures: checked ? 'true' : '',
                })
              }}
              checked={filters.only_without_pictures === 'true'}
            />
          </div>
        </div>
      </div>
      {location.pathname.indexOf('lista') !== -1 && (
      <div className={styles.Filter}>
        <div className={styles.FilterTitle}>
          {t('MONUMENTS_WITHOUT_COORDINATES')}
        </div>
        <div className="d-flex align-items-center">
          <div className="d-flex align-items-center">
            <ReactSwitch
              offColor="#D0DFE4"
              size={15}
              checkedIcon={false}
              uncheckedIcon={false}
              onColor="#40BAEC"
              onChange={(checked) => {
                setFilters({
                  ...filters,
                  without_coordinates: checked ? 'true' : '',
                })
              }}
              checked={filters.without_coordinates === 'true'}
            />
          </div>
        </div>
      </div>
      )}
      {username && user.admin_geo_contexts.includes(activeGeocontext?.id) && (
        <div className={styles.Filter}>
          <div className={styles.FilterTitle}>{t('ENTRIES_DISABLED')}</div>
          <div className="d-flex align-items-center">
            <ReactSwitch
              offColor="#D0DFE4"
              size={15}
              checkedIcon={false}
              uncheckedIcon={false}
              onColor="#CF4130"
              onChange={(checked) => {
                setFilters({
                  ...filters,
                  only_disabled: checked ? 'true' : '',
                })
              }}
              checked={filters.only_disabled === 'true'}
            />
          </div>
        </div>
      )}
    </div>
  )
}
