import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { ReactComponent as WorldPrimary } from '../../../assets/world-primary.svg'
import { ReactComponent as CheckOrderingIcon } from '../../../assets/ordering-checked.svg'
import { ReactComponent as UncheckOrderingIcon } from '../../../assets/ordering-unchecked.svg'
import classNames from 'classnames'
import styles from './ModalLanguage.module.css'
import { useLocation, useNavigate } from 'react-router-dom'
import { useLingue } from '../../../hooks/languages'

export default function ModalLanguage({
  modalLang,
  setModalLang,
}: {
  modalLang: boolean
  setModalLang: (modalLang: boolean) => void
}) {
  const { t, i18n } = useTranslation()
  const [lang, setLang] = useState(i18n.language)
  const navigate = useNavigate()
  const { pathname, search } = useLocation()
  const { data: lingue } = useLingue()
  
  return (
    <Modal show={modalLang} onHide={() => setModalLang(false)} centered>
      <Modal.Body className={styles.ModalBody}>
        <div className={styles.TitleModal}>
          <WorldPrimary className="me-2" /> {t('SELECT_LANGUAGE')}
        </div>
        <div className={styles.DescriptionModal}>
          {t('SELECT_THE_LANGUAGE_IN_WHICH_YOU_WANT_TO_USE_THE_APPLICATION')}
        </div>
        <div className={styles.ListOrderingItems}>
          {lingue?.map((lingua) => (
            <div
              key={lingua.id}
              className={classNames(styles.OrderingItem, {
                [styles.OrderingItemActive]: lang === lingua.code,
              })}
              onClick={() => {
                setLang(lingua.code)
              }}
            >
              <div>
                {lang === lingua.code ? <CheckOrderingIcon /> : <UncheckOrderingIcon />}
              </div>
              <div className={styles.OrderingItemTitle}>{lingua.name}</div>
            </div>
          ))}
          
        </div>
        <div className="mt-4">
          <button
            className={styles.ButtonConfirm}
            onClick={() => {
              i18n.changeLanguage(lang)
              // change language in url
              const url = `${pathname.replace(
                /\/[a-z]{2}\//,
                `/${lang}/`
              )}${search}`
              navigate(url)
              setModalLang(false)
            }}
          >
            {t('CONTINUE')}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}
