import { useTranslation } from 'react-i18next'
import styles from './Profile.module.css'
import { ReactComponent as LoginIcon } from '../../../assets/login.svg'
import { ReactComponent as LoginWhite } from '../../../assets/login-white.svg'
import { ReactComponent as ProfileUser } from '../../../assets/profile-user.svg'
import { ReactComponent as Logout } from '../../../assets/logout.svg'
import { ReactComponent as Close } from '../../../assets/close.svg'
import { API_URL, URL_WIKI } from '../../../const'
import { useAuthActions, useAuthUser } from 'use-eazy-auth'
import { PersonalImages } from '../../PersonalImages'
import { useLocation } from 'react-router-dom'

export default function Profile({
  isOpenProfile,
  setIsOpenProfile,
}: {
  isOpenProfile: boolean
  setIsOpenProfile: (value: boolean) => void
}) {
  const { t, i18n } = useTranslation()
  const { user } = useAuthUser()
  const username = user ? user.username.replace('mw--', ' ') : ''
  const { logout } = useAuthActions()
  const { pathname, search } = useLocation()
  return (
    <div className={styles.ProfileModal}>
      <div className="w-100 h-100 d-flex justify-content-center position-relative">
        <div
          className={styles.ProfileContent}
          style={{
            width: 532,
            transition: 'width 0.3s ease-in-out',
          }}
        >
          <div
            className={
              user ? styles.ProfileContainerUser : styles.ProfileContainer
            }
          >
            <div className="d-flex justify-content-end">
              <div
                className={styles.Close}
                onClick={() => setIsOpenProfile(false)}
              >
                <Close />
              </div>
            </div>
            {user ? (
              <div>
                <div className={styles.LoginEffettuato}>
                  {t('LOGIN_SUCCESSFUL_WITH_WIKIMEDIA_COMMONS')}
                </div>
                <div className="mt-3 d-flex align-items-center">
                  <div>
                    <ProfileUser />
                  </div>
                  <div className="ms-2">
                    <div className={styles.UserLabel}>Username:</div>
                    <div className={styles.NomeUtente}>
                      {user.username.replace('mw--', ' ')}
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  <button
                    className={styles.LogoutButton}
                    onClick={() => {
                      logout()
                    }}
                  >
                    <Logout /> {t('LOGOUT')}
                  </button>
                  {!user.wlm_email_verified && (
                    <div className="alert alert-danger mt-3" role="alert" style={{ fontSize: 14 }}>
                      <span>{t('TEXT_EMAIL_1_PROFILE')}</span>
                      <span>{t('TEXT_EMAIL_2_PROFILE')}</span>(
                      <a href="https://commons.wikimedia.org/wiki/Special:Preferences" style={{
                        lineBreak: 'anywhere',
                      }}>
                        https://commons.wikimedia.org/wiki/Special:Preferences
                      </a>
                      )<span>{t('TEXT_EMAIL_3_PROFILE')}</span>
                    </div>
                  )}
                </div>

                {username && (
                  <div className="mt-3">
                    <PersonalImages rows={3} cols={5} />
                    <a
                      href={`${URL_WIKI}/Special:ListFiles/${username.replace(
                        ' ',
                        '_'
                      )}`}
                      className={styles.ButtonImagesProfile}
                    >
                      <img width={16} src="/commons.png" alt="Commons" />{' '}
                      {t('SEE_UPLOADED_IMAGES_TO_WIKIMEDIA_COMMONS')}
                    </a>
                  </div>
                )}
              </div>
            ) : (
              <>
                <div className="mt-5 d-flex flex-column align-items-center justify-content-center">
                  <LoginIcon />
                  <div className={styles.TextLogin}>{t('LOGIN_TEXT')}</div>
                </div>
                <div className="mt-2">
                  <button
                    className={styles.LoginButton}
                    onClick={() => {
                      localStorage.setItem('redirectUrl', pathname + search)
                      window.location.href = `${API_URL}/oauth/oauth-login?redirect_uri=${window.location.href}`
                    }}
                  >
                    <LoginWhite className="me-1" /> {t('LOGIN')}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
