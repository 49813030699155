// countryContext.tsx

import React, { createContext, useContext, useState } from 'react'

interface CountryContextProps {
  country: {
    id: number | undefined
    label: string
  }
  setCountry: (
    country: {
      id: number | undefined
      label: string
    }
  ) => void
}

export const PaeseContext = createContext<CountryContextProps>({
  country: {
    id: undefined,
    label: '',
  },
  setCountry: () => {},
})

export const PaeseContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  //   check if the country is already in the local storage
  let countryLocal = {
    id: undefined,
    label: '',
  }
  try {
    const country = localStorage.getItem('paese')
    if (country) {
      countryLocal = JSON.parse(country)
    }
  } catch (error) {
    countryLocal = {
      id: undefined,
      label: '',
    }
  }

  const [country, setCountry] = useState<{
    id: number | undefined
    label: string
  }>(countryLocal)

  return (
    <PaeseContext.Provider value={{ country, setCountry }}>
      {children}
    </PaeseContext.Provider>
  )
}

export function usePaeseContext() {
  const { country, setCountry } = useContext(PaeseContext)
  return { country, setCountry }
}
