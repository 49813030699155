import styles from './BottomNavigation.module.css'
import { ReactComponent as Map } from '../../assets/map-mobile.svg'
import { ReactComponent as List } from '../../assets/list-mobile.svg'
import { ReactComponent as MapActive } from '../../assets/map-active-mobile.svg'
import { ReactComponent as ListActive } from '../../assets/list-active-mobile.svg'
import { ReactComponent as LegendIcon } from '../../assets/legend-mobile.svg'
import { ReactComponent as LegendActive } from '../../assets/legend-active-mobile.svg'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import LangLink from '../LangLink'
import { useQsFilters } from '../../hooks/filters'
import { useState } from 'react'
import Legend from '../Mobile/Legend'

const getFilters = (params: URLSearchParams) => ({
  search: params.get('search') ?? '',
  municipality: params.get('municipality') ?? '',
  app_category: params.get('app_category') ?? '',
  in_contest: params.get('in_contest') ?? 'true',
  only_without_pictures: params.get('only_without_pictures') ?? '',
  user_lat: params.get('user_lat') ?? '',
  user_lon: params.get('user_lon') ?? '',
  ordering: params.get('ordering') ?? '',
  monument_id: params.get('monument_id') ?? '',
  monument_lat: params.get('monument_lat') ?? '',
  monument_lon: params.get('monument_lon') ?? '',
  geo_context: params.get('geo_context') ?? '',
  without_coordinates: params.get('without_coordinates') ?? '',
  only_disabled: params.get('only_disabled') ?? '',
})

export default function BottomNavigation({
  legend,
  setLegend,
}: {
  legend: boolean
  setLegend: (legend: boolean) => void
}) {
  const location = useLocation()
  const { i18n, t } = useTranslation()
  const { filters } = useQsFilters(getFilters)
  const currentPath = location.pathname
  return (
    <div className={styles.BottomNavigation}>
      <LangLink
        to={`/mappa?${new URLSearchParams({
          search: filters.search,
          municipality: filters.municipality,
          app_category: filters.app_category,
          in_contest: filters.in_contest,
          only_without_pictures: filters.only_without_pictures,
          user_lat: filters.user_lat || '',
          user_lon: filters.user_lon || '',
          ordering: filters.ordering,
          monument_id: filters.monument_id || '',
          monument_lat: filters.monument_lat || '',
          monument_lon: filters.monument_lon || '',
          geo_context: filters.geo_context,
          without_coordinates: filters.without_coordinates,
        })}`}
        className="no-link"
      >
        <div className={styles.Map}>
          {currentPath === '/mappa' + i18n.language + '/' ||
          currentPath === '/mappa' ||
          currentPath.indexOf('mappa') !== -1 ? (
            <MapActive fill="white" />
          ) : (
            <Map />
          )}
        </div>
      </LangLink>
      <LangLink
        to={`/lista?${new URLSearchParams({
          search: filters.municipality ? filters.search : '',
          municipality: filters.municipality,
          app_category: filters.app_category,
          in_contest: filters.in_contest,
          only_without_pictures: filters.only_without_pictures,
          user_lat: filters.user_lat || '',
          user_lon: filters.user_lon || '',
          ordering: filters.ordering,
          monument_id: filters.monument_id || '',
          monument_lat: filters.monument_lat || '',
          monument_lon: filters.monument_lon || '',
          geo_context: filters.geo_context,
          without_coordinates: filters.without_coordinates,
          only_disabled: filters.only_disabled,
        })}`}
        className="no-link"
      >
        <div className={styles.List}>
          {currentPath.indexOf('lista') !== -1 ? <ListActive /> : <List />}
        </div>
      </LangLink>
      <div className={styles.Legend} onClick={() => setLegend(!legend)}>
        {legend ? <LegendActive /> : <LegendIcon />}
      </div>
      
    </div>
  )
}
