import slugify from 'slugify'
import { useMediaQuery } from 'usehooks-ts'
import { useTopContextState } from './context/TopContext'
import { usePaeseContext } from './context/PaeseContext'

export function smartSlug(id: number, text: string) {
  return (
    id +
    '_' +
    slugify(text, {
      lower: true,
      strict: true,
    })
  )
}

export function parseSmartSlug(text: string) {
  return text.split('_')[0]
}

export function getLabelFromSlug(text: string) {
  return text.split('_')[1].replace(/-/g, ' ')
}

export function isBrowserMobile() {
  return !useMediaQuery(
    // '((hover: none) and (pointer: coarse)) or (max-width: 1024px)'
    '(min-width: 1024px) or ((hover: hover) and (pointer: fine))'
  )
}

export function getActiveGeocontext() {
  const { geocontexts } = useTopContextState()
  const { country } = usePaeseContext()

  return geocontexts.find((geocontext) => geocontext.id === country.id) 
}

export function unicodeToChar(text: string | undefined) {
  try {
    if (!text || text === undefined || text === '') return ''
    const splited = text.split(' ')
    let str = ''
    for (let i = 0; i < splited.length; i++) {
      splited[i] = splited[i].replace('U', '0')
      splited[i] = splited[i].replace('+', 'x')
      str += String.fromCodePoint(parseInt(splited[i], 16))
    }
    return str
  } catch (e) {
    console.error('Cannot decode flag')
    console.error(e)
    return ''
  }
}
