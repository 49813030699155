import { useQuery } from '@tanstack/react-query'
import { API_URL } from '../const'
import { Lingua } from '../types'
import axios from 'axios'
import { serializeQueryParams } from './utils'

export async function getLanguages(
  params: Record<string, any> = {},
  signal?: AbortSignal
) {
  return (
    await axios.get(`${API_URL}/languages/`, {
      signal,
      params: serializeQueryParams(params),
    })
  ).data as Lingua[]
}

export function useLingue(params: Record<string, any> = {}) {
  return useQuery(
    ['lingue', params],
    ({ signal }) => getLanguages(params, signal),
    {
      keepPreviousData: true,
      suspense: false,
    }
  )
}