import { createContext, useContext, useState } from 'react'
import { getActiveGeocontext } from '../utils'

interface DonationContextProps {
  setDonationModal: (donationModal: boolean) => void
  donationModal: boolean
  checkDonationModal: () => void
}

const DonationContext = createContext<DonationContextProps>({
  donationModal: false,
  setDonationModal: () => {},
  checkDonationModal: () => {},
})

export function DonationProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const activeGeocontext = getActiveGeocontext()

  const prob = activeGeocontext?.donations_popup_probability ?? 0

  const checkDonationModal = () => {
    const random = Math.floor(Math.random() * 100)
    if (random <= prob && random !== 0) {
      setDonationModal(true)
    }
  }
  const [donationModal, setDonationModal] = useState<boolean>(false)

  return (
    <DonationContext.Provider
      value={{
        donationModal,
        setDonationModal,
        checkDonationModal,
      }}
    >
      {children}
    </DonationContext.Provider>
  )
}

export function useDonationContext() {
  const filters = useContext(DonationContext)
  return filters
}
