import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { SwiperSlide, Swiper } from 'swiper/react'
import { ReactComponent as Close } from '../../assets/close-onboarding.svg'
import { ReactComponent as ArrowLeftSlideShow } from '../../assets/left-slideshow-arrow.svg'
import { ReactComponent as ArrowRightSlideShow } from '../../assets/right-slideshow-arrow.svg'
import { ReactComponent as Logo } from '../../assets/wiki_logo.svg'
import Topbar from './Topbar'
import { Navigation } from 'swiper'
import { useTranslation } from 'react-i18next'
import Scraping from './Scraping'
import Profile from './Profile'
import { useDonationContext } from '../../context/DonationContext'
import { Modal } from 'react-bootstrap'
import { getActiveGeocontext } from '../../utils'

export function SlidesPresentazioneDesktop({
  setPresentazione,
  isFromPageWiki,
}: {
  setPresentazione: (presentazione: boolean) => void
  isFromPageWiki?: boolean
}) {
  const [activeSlide, setActiveSlide] = useState<number>(0)
  const sliderRef = useRef<any>()
  const { t } = useTranslation()

  return (
    <div
      className="w-100 h-100"
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        zIndex: 12,
        backgroundColor: 'rgba(0,0,0,0.6)',
      }}
    >
      <Swiper
        slidesPerView={1}
        onSlideChange={(swiper) => {
          setActiveSlide(swiper.activeIndex)
        }}
        className="position-relative"
        // className="h-100 w-100"
        onSwiper={(swiper) => {
          sliderRef.current = swiper
        }}
        modules={[Navigation]}
        spaceBetween={20}
        navigation
        onInit={(swiper) => {
          swiper.slideTo(activeSlide, 0)
        }}
        style={{
          height:
            'calc(100% - var(--topbar-desktop-height) - var(--bottom-desktop-navigation-height) - 82px)',
          width: '60%',

          marginTop: 'calc(var(--topbar-desktop-height) + 16px)',
        }}
      >
        <SwiperSlide className={'position-relative'}>
          <div className={'SlideDesktop'}>
            <div className="d-flex justify-content-center align-items-center h-100 flex-column">
              <div>
                <Logo />
              </div>
              <div className="mt-4 TitleSlideDesktop">
                {t('WIKI_LOVES_MONUMENTS')}
              </div>
              <div className="mt-5 SubtitleSlideDesktop">{t('WELCOME')}!</div>
              <div className="mt-4">{t('SLIDE_1_TEXT')}</div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className={'position-relative'}>
          <div className={'SlideDesktop'}>
            <div className="d-flex justify-content-center align-items-center h-100 flex-column">
              <div className="mt-2 SubtitleSlideDesktop">{t('STATE')}</div>
              <div className='mt-4 mb-3'>
                {t('SLIDE_2_TEXT')}
              </div>
              <img src={'/slides/desktop/slide2.svg'} alt="Slide2" />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className={'position-relative'}>
          <div className={'SlideDesktop'}>
            <div className="d-flex justify-content-center align-items-center h-100 flex-column">
              <div className="mt-2 SubtitleSlideDesktop">{t('MENU')}</div>
              <div className='mt-4 mb-2 text-center px-5'>
                {t('SLIDE_3_TEXT')}
              </div>
              <img src={'/slides/desktop/slide3.svg'} alt="Slide2"  height={300} />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className={'position-relative'}>
          <div className={'SlideDesktop'}>
            <div className="d-flex justify-content-center align-items-center h-100 flex-column">
              <div className="mt-2 SubtitleSlideDesktop">{t('MAP')}</div>
              <div className='mt-4 mb-3 text-center px-5'>
                {t('SLIDE_4_TEXT')}
              </div>
              <img src={'/slides/desktop/slide4.svg'} alt="Slide2"  height={300} />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className={'position-relative'}>
          <div className={'SlideDesktop'}>
            <div className="d-flex justify-content-center align-items-center h-100 flex-column">
              <div className="mt-2 SubtitleSlideDesktop">{t('SEARCH_IN_LIST')}</div>
              <div className='mt-4 mb-3 text-center px-5'>
                {t('SLIDE_5_TEXT')}
              </div>
              <img src={'/slides/desktop/slide5.svg'} alt="Slide2"  height={300} />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className={'position-relative'}>
          <div className={'SlideDesktop'}>
            <div className="d-flex justify-content-center align-items-center h-100 flex-column">
              <div className="mt-2 SubtitleSlideDesktop">{t('LEGEND')}</div>
              <div className='mt-4 mb-3 text-center px-5'>
                {t('SLIDE_6_TEXT')}
              </div>
              <img src={'/slides/desktop/slide6.svg'} alt="Slide2"  height={300} />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className={'position-relative'}>
          <div className={'SlideDesktop'}>
            <div className="d-flex justify-content-center align-items-center h-100 flex-column">
              <div className="mt-2 SubtitleSlideDesktop">{t('LOGIN_AND_UPLOAD_PHOTO')}</div>
              <div className='mt-4 mb-3 text-center px-5'>
                {t('SLIDE_7_TEXT')}
              </div>
              <img src={'/slides/desktop/slide7.svg'} alt="Slide2"  height={300} />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className={'position-relative'}>
          <div className={'SlideDesktop'}>
            <div className="d-flex justify-content-center align-items-center h-100 flex-column">
              <div className="mt-2 SubtitleSlideDesktop">{t('PREVIEW_PHOTO')}</div>
              <div className='mt-4 mb-3 text-center px-5'>
                {t('SLIDE_8_TEXT')}
              </div>
              <img src={'/slides/desktop/slide8.svg'} alt="Slide2"  height={300} />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className={'position-relative'}>
          <div className={'SlideDesktop'}>
            <div className="d-flex justify-content-center align-items-center h-100 flex-column">
              <div>
                <Logo />
              </div>
              <div className="mt-4 TitleSlideDesktop">
                {t('WIKI_LOVES_MONUMENTS')}
              </div>
              <div className="mt-4">{t('SLIDE_9_TEXT')}</div>
              <div className="mt-5 SubtitleSlideDesktop">{t('THANKS')}!</div>
              
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
      <div
        onClick={() => {
          setPresentazione(false)
        }}
        className="button-close-slides-mobile pointer"
      >
        <Close />
      </div>
      <div className={'pagination-container-onboarding'}>
        <ArrowLeftSlideShow
          onClick={() => {
            if (activeSlide > 0) {
              sliderRef.current?.slidePrev()
            }
          }}
          className={classNames('me-3', {
            pointer: activeSlide > 0,
          })}
          fill={
            activeSlide > 0
              ? 'var(--colori-neutri-white)'
              : 'var(--colori-neutri-gray-2)'
          }
        />
        <div className={'current-slide-onboarding'}>
          {activeSlide + 1} / 9
        </div>
        <div
          className={classNames('ms-3', {
            pointer: activeSlide < 8,
          })}
        >
          <ArrowRightSlideShow
            onClick={() => {
              if (activeSlide < 8) {
                sliderRef.current?.slideNext()
              }
            }}
            fill={
              activeSlide < 8
                ? 'var(--colori-neutri-white)'
                : 'var(--colori-neutri-gray-2)'
            }
          />
        </div>
      </div>
    </div>
  )
}

export default function Layout({ children }: { children: React.ReactNode }) {
  const [presentazione, setPresentazione] = useState<boolean>(false)

  useEffect(() => {
    if (!localStorage.getItem('presentazione')) {
      setPresentazione(true)
      localStorage.setItem('presentazione', 'true')
    }
  }, [])

  const [scrapingOpen, setScrapingOpen] = useState<boolean>(false)
  const [profileOpen, setProfileOpen] = useState<boolean>(false)
  const { donationModal, setDonationModal } = useDonationContext()
  const activeGeocontext = getActiveGeocontext()
  const { i18n, t } = useTranslation()

  return (
    <div className="h-100 w-100">
      {presentazione && (
        <SlidesPresentazioneDesktop setPresentazione={setPresentazione} />
      )}
      <Topbar
        setScrapingOpen={setScrapingOpen}
        scrapingOpen={scrapingOpen}
        setProfileOpen={setProfileOpen}
        profileOpen={profileOpen}
      />
      <div className="block-content-desktop">{children}</div>
      {scrapingOpen && (
        <Scraping
          setScrapingOpen={setScrapingOpen}
          scrapingOpen={scrapingOpen}
        />
      )}
      {profileOpen && (
        <Profile
          isOpenProfile={profileOpen}
          setIsOpenProfile={setProfileOpen}
        />
      )}
      {donationModal && activeGeocontext?.enable_donations_text && (
        <Modal
          show={donationModal}
          onHide={() => setDonationModal(false)}
          className="modal-donation"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {t('DONATIONS')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="">
              <div className="">
                
                <div
                dangerouslySetInnerHTML={{
                  __html: activeGeocontext?.donation_texts[i18n.language] ?? '',

                }}
                ></div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  )
}
