import { AppCategory } from '../../types'

interface Props {
  monument: {
    app_category: number
    pictures_count: number
    in_contest: boolean
  }
  category: AppCategory | undefined
}

export default function getMarkerMap({ monument, category }: Props) {
  const photosNumber = monument.pictures_count ?? 0
  const inContest = monument.in_contest

  if (photosNumber === 0) {
    if (inContest) {
      return category?.icon.icon_pin_none_contest
    } else {
      return category?.icon.icon_pin_none
    }
  } else if (photosNumber > 0 && photosNumber <= 10) {
    if (inContest) {
      return category?.icon.icon_pin_some_contest
    } else {
      return category?.icon.icon_pin_some
    }
  } else {
    if (inContest) {
      return category?.icon.icon_pin_many_contest
    } else {
      return category?.icon.icon_pin_many
    }
  }
  
}
