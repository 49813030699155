import styles from './Topbar.module.css'
import { ReactComponent as Logo } from '../../../assets/wiki-logo-mobile.svg'
import { ReactComponent as Bell } from '../../../assets/bell-primary.svg'
import { ReactComponent as Burger } from '../../../assets/burger.svg'
import { ReactComponent as LegendWhite } from '../../../assets/legend-white.svg'
import { ReactComponent as LoginWhite } from '../../../assets/login-white.svg'
import { ReactComponent as ScrapingWhite } from '../../../assets/scraping-white.svg'
import { ReactComponent as Account } from '../../../assets/account.svg'
import { useTranslation } from 'react-i18next'
import WikiLoveMonuments from '../../Mobile/WikiLoveMonuments'
import { useMemo, useState } from 'react'
import classNames from 'classnames'
import { useDetectClickOutside } from 'react-detect-click-outside'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuthUser } from 'use-eazy-auth'
import Legend from '../Legend'
import Menu from '../Menu'
import ModalLanguage from '../ModalLanguage'
import ModalPaese from '../ModalPaese'
import { API_URL } from '../../../const'
import { getActiveGeocontext, unicodeToChar } from '../../../utils'
import { SlidesPresentazioneDesktop } from '../Layout'

export default function Topbar({
  setScrapingOpen,
  scrapingOpen,
  setProfileOpen,
  profileOpen,
}: {
  setScrapingOpen: (value: boolean) => void
  scrapingOpen: boolean
  setProfileOpen: (value: boolean) => void
  profileOpen: boolean
}) {
  const { t, i18n } = useTranslation()
  const [infoWiki, setInfoWiki] = useState(false)
  const [presentazione, setPresentazione] = useState(false)
  // const { activeContests } = useTopContextState()
  const [show, setShow] = useState(false)
  const [showLegend, setShowLegend] = useState(false)
  const [modalLang, setModalLang] = useState(false)
  const [modalPaese, setModalPaese] = useState(false)
  const { user } = useAuthUser()
  const username = user ? user.username.replace('mw--', ' ') : ''

  const legendRed = useDetectClickOutside({
    onTriggered: () => setShowLegend(false),
  })
  const menuRed = useDetectClickOutside({ onTriggered: () => setShow(false) })
  const { pathname, search } = useLocation()

  const activeGeocontext = getActiveGeocontext()

  const activeContest = useMemo(() => {
    if (!activeGeocontext) return null
    if (!activeGeocontext.next_contest) return null
    if (activeGeocontext.next_contest.active) return activeGeocontext.next_contest
    return null
  }, [activeGeocontext])

  const navigate = useNavigate()

  return (
    <>
      <div className={styles.Topbar}>
        <div
          className={styles.WikiLoveMonuments}
          onClick={() => {
            navigate('/')
          }}
        >
          <Logo /> {t('WIKI_LOVES_MONUMENTS')}
        </div>
        {activeContest && (
          <div>
            <div className={styles.ButtonConcorso}>
              <Bell />{' '}
              {i18n.language === 'it'
                ? activeContest?.label
                : activeContest?.label_en}
            </div>
          </div>
        )}
        <div className="d-flex align-items-center h-100">
          {username &&
            user.admin_geo_contexts.includes(activeGeocontext?.id) && (
              <div
                className={styles.LinkScraping}
                onClick={() => {
                  setProfileOpen(false)
                  setScrapingOpen(!scrapingOpen)
                }}
              >
                <ScrapingWhite className="me-2" />
                Scraping
              </div>
            )}
          <div>
            <div
              onClick={() => {
                if (username) {
                  setScrapingOpen(false)
                  setProfileOpen(!profileOpen)
                } else {
                  localStorage.setItem('redirectUrl', pathname + search)
                  window.location.href = `${API_URL}/oauth/oauth-login?redirect_uri=${window.location.href}`
                }
              }}
              className={styles.Login}
            >
              {username ? (
                <Account className="me-2" />
              ) : (
                <LoginWhite className="me-2" />
              )}{' '}
              {username ? username : t('LOGIN')}
            </div>
          </div>
          <div
            className={classNames('me-3 position-relative', styles.Legend, {
              [styles.LegendOpen]: showLegend,
            })}
            ref={legendRed}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              setShow(false)
              setShowLegend(!showLegend)
            }}
          >
            <LegendWhite />
            {showLegend && <Legend />}
          </div>
          <div className="me-3 pointer" onClick={() => setModalPaese(true)}>
            <span style={{ fontSize: 25 }}>
              {unicodeToChar(activeGeocontext?.flag)}
            </span>
          </div>
          <div
            style={{
              width: '0.5px',
              height: '60%',
              backgroundColor: '#D0DFE4',
            }}
            className="me-2"
          ></div>
          <div>
            <div
              className={classNames(styles.Burger, {
                [styles.BurgerOpen]: show,
              })}
              ref={menuRed}
            >
              <Burger
                className="pointer"
                onClick={() => {
                  setShow(!show)
                  setShowLegend(false)
                }}
              />
            </div>
            {show && (
              <Menu
                setInfoWiki={setInfoWiki}
                setModalLang={setModalLang}
                setPresentazione={setPresentazione}
                setModalPaese={setModalPaese}
              />
            )}
          </div>
        </div>
      </div>
      <ModalLanguage modalLang={modalLang} setModalLang={setModalLang} />
      <ModalPaese modalPaese={modalPaese} setModalPaese={setModalPaese} />

      <WikiLoveMonuments
        infoWiki={infoWiki}
        setPresentazione={setPresentazione}
        setInfoWiki={(infoWiki: boolean) => setInfoWiki(infoWiki)}
      />
      {presentazione && (
        <SlidesPresentazioneDesktop
          isFromPageWiki
          setPresentazione={setPresentazione}
        />
      )}
    </>
  )
}
