import classNames from 'classnames'
import styles from './Menu.module.css'
import { useTranslation } from 'react-i18next'
import { ReactComponent as Globe } from '../../../assets/globe.svg'
import { ReactComponent as Language } from '../../../assets/language.svg'
import { ReactComponent as Tutorial } from '../../../assets/tutorial.svg'
import { ReactComponent as LogoMenu } from '../../../assets/logo-menu.svg'
import { ReactComponent as LoginWhite } from '../../../assets/login-white.svg'
import { ReactComponent as Donation } from '../../../assets/donation.svg'
import { usePaeseContext } from '../../../context/PaeseContext'
import { useAuthActions, useAuthUser } from 'use-eazy-auth'
import { API_URL } from '../../../const'
import { useLocation } from 'react-router-dom'
import { useDonationContext } from '../../../context/DonationContext'
import { getActiveGeocontext } from '../../../utils'

export default function Menu({
  setModalLang,
  setPresentazione,
  setInfoWiki,
  setModalPaese,
}: {
  setModalLang: (value: boolean) => void
  setPresentazione: (value: boolean) => void
  setInfoWiki: (value: boolean) => void
  setModalPaese: (value: boolean) => void
}) {
  const { t, i18n } = useTranslation()
  const { country } = usePaeseContext()
  const { user } = useAuthUser()
  const username = user ? user.username.replace('mw--', ' ') : ''
  const { logout } = useAuthActions()
  const { pathname, search } = useLocation()
  const { setDonationModal } = useDonationContext()
  const activeGeocontext = getActiveGeocontext()
  return (
    <div className={styles.Menu}>
      <div className="d-flex flex-column justify-content-between h-100">
        <div>
          <div className={styles.TitleMenu}>{t('WIKI_LOVES_MONUMENTS')}</div>
          <div className={styles.SloganUser}>
            {t('WELCOME_BACK_TO_WLM')} {username ? `, ${username}!` : '!'}
          </div>
          <div className="mt-5">
            <div
              className={styles.ListItemMenu}
              onClick={() => setModalPaese(true)}
            >
              <Globe className="me-3" />{' '}
              <div>
                <div>{t('STATE')}</div>
                <div className={styles.ListItemOptionSelected}>
                  {country.label}
                </div>
              </div>
            </div>
            <div
              className={classNames(styles.ListItemMenu, 'mt-3')}
              onClick={() => setModalLang(true)}
            >
              <Language className="me-3" />{' '}
              <div>
                <div>{t('LANGUAGE')}</div>
                <div className={styles.ListItemOptionSelected}>
                  {i18n.language === 'it'
                    ? t('ITALIAN')
                    : i18n.language === 'en'
                    ? t('ENGLISH')
                    : i18n.language === 'fr'
                    ? t('francese')
                    : t('mongolo')}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          {activeGeocontext?.enable_donations_text && (
          <div
            className={styles.ListItemMenu}
            onClick={() => setDonationModal(true)}
          >
            <Donation className="me-3" /> {t('DONATIONS')}
          </div>
          )}
          <div
            className={classNames('mt-3', styles.ListItemMenu)}
            onClick={() => setPresentazione(true)}
          >
            <Tutorial className="me-3" /> {t('APP_TUTORIAL')}
          </div>
          <div
            className={classNames('mt-3', styles.ListItemMenu)}
            onClick={() => setInfoWiki(true)}
          >
            <LogoMenu className="me-3" /> {t('WHAT_IS_WLM')}
          </div>
        </div>
        <div className="pt-4 border-top border-white">
          <div className="d-flex align-items-center justify-content-end">
            <div
              className={styles.ListItemMenu}
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                if (username) {
                  logout()
                } else {
                  localStorage.setItem('redirectUrl', pathname + search)
                  window.location.href = `${API_URL}/oauth/oauth-login?redirect_uri=${window.location.href}`
                }
              }}
            >
              <LoginWhite className="me-3" />{' '}
              {username ? t('LOGOUT') : t('LOGIN')}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
