import styles from './Map.module.css'
import { ReactComponent as MyLocation } from '../../../assets/my-location.svg'
import { ReactComponent as CameraTransparent } from '../../../assets/camera-transparent.svg'
import { ReactComponent as Map } from '../../../assets/map.svg'
import { ReactComponent as MapActive } from '../../../assets/map-active.svg'
import { ReactComponent as ListIcon } from '../../../assets/list.svg'
import { ReactComponent as ListIconActive } from '../../../assets/list-active.svg'
import IconMonument from '../../../components/IconMonument'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { fromLonLat } from 'ol/proj'
import { createPortal } from 'react-dom'
import { MarkerProps } from '../../../types'
import { Link, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import ReactSwitch from 'react-switch'
import { getActiveGeocontext, unicodeToChar } from '../../../utils'

interface MapContainerProps {
  mapElement: React.MutableRefObject<HTMLDivElement | null>
  handleLocationClick: () => void
  infoMarker: MarkerProps | null
  markersCluster: MarkerProps[] | null
  detail: number | null
  map: any
  loading: boolean
  popup: any
  popupMarker: any
  filters: Record<string, any>
  setFilters: (filters: Record<string, any>) => void
  setDetail: (detail: number | null) => void
}

export default function MapContainer({
  mapElement,
  handleLocationClick,
  infoMarker,
  markersCluster,
  detail,
  map,
  loading,
  popup,
  popupMarker,
  filters,
  setFilters,
  setDetail,
}: MapContainerProps) {
  const popupRef = useRef<HTMLDivElement>(null)
  const markersClusterRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    // refreshCoordinates()
    if (infoMarker) {
      popup.show(
        infoMarker.feature.getGeometry().getCoordinates(),
        '<div></div>'
      )
    } else {
      popup.hide()
    }
  }, [infoMarker, popup])

  useEffect(() => {
    if (markersCluster) {
      popupMarker.show(
        markersCluster[0].feature.getGeometry().getCoordinates(),
        '<div></div>'
      )
    } else {
      popupMarker.hide()
    }
  }, [markersCluster, popupMarker])

  const pointerFeatures = useCallback(
    (e: any) => {
      if (!e.dragging) {
        map.getTargetElement().style.cursor = map.hasFeatureAtPixel(
          map.getEventPixel(e.originalEvent)
        )
          ? 'pointer'
          : ''
      }
    },
    [map]
  )

  useEffect(() => {
    map && map.on('pointermove', pointerFeatures)

    return () => {
      map && map.un('pointermove', pointerFeatures)
    }
  }, [map])

  const [switchCluster, setSwitchCluster] = useState(false)

  useEffect(() => {
    if (!map) return
    const v = map.getView()
    v.on('change', () => {
      const zoom = v.getZoom()
      if (zoom <= 11) {
        setSwitchCluster(false)
      } else {
        setSwitchCluster(true)
      }
    })
  }, [map])

  const { t, i18n } = useTranslation()
  const location = useLocation()
  const currentPath = location.pathname
  const activeGeocontext = getActiveGeocontext()

  return (
    <div className={styles.MapContainer}>
      <div
        ref={mapElement}
        id="map"
        className="w-100 position-relative"
        style={{
          height: '100%',
        }}
      >
        <div className={styles.SwitchMapList}>
          <Link
            className={classNames({
              [styles.SwitchItemActive]: true,
            })}
            to={`/${i18n.language}/mappa?${new URLSearchParams({
              search: filters.search,
              municipality: filters.municipality,
              app_category: filters.app_category,
              in_contest: filters.in_contest,
              only_without_pictures: filters.only_without_pictures,
              user_lat: filters.user_lat || '',
              user_lon: filters.user_lon || '',
              ordering: filters.ordering,
              monument_id: filters.monument_id || '',
              monument_lat: filters.monument_lat || '',
              monument_lon: filters.monument_lon || '',
              cluster_active: filters.cluster_active,
              only_disabled: filters.only_disabled,
              geo_context:
                filters.geo_context || String(getActiveGeocontext()?.id),
              without_coordinates: filters.without_coordinates,
            })}`}
          >
            {currentPath === '/' + i18n.language + '/' ||
            currentPath === '/' ||
            currentPath.indexOf('mappa') !== -1 ? (
              <MapActive className="me-1" />
            ) : (
              <Map className="me-1" />
            )}
            {t('MAP')}
          </Link>
          <Link
            to={`/${i18n.language}/lista?${new URLSearchParams({
              search: filters.search,
              municipality: filters.municipality,
              app_category: filters.app_category,
              in_contest: filters.in_contest,
              only_without_pictures: filters.only_without_pictures,
              user_lat: filters.user_lat || '',
              user_lon: filters.user_lon || '',
              ordering: filters.ordering,
              monument_id: filters.monument_id || '',
              only_disabled: filters.only_disabled,
              monument_lat: filters.monument_lat || '',
              monument_lon: filters.monument_lon || '',
              cluster_active: filters.cluster_active,
              geo_context:
                filters.geo_context || String(getActiveGeocontext()?.id),
              without_coordinates: filters.without_coordinates,
            })}`}
            className={classNames({
              [styles.SwitchItem]: true,
            })}
          >
            {currentPath === '/' + i18n.language + '/lista' ||
            currentPath.indexOf('lista') !== -1 ? (
              <ListIconActive className="me-1" />
            ) : (
              <ListIcon className="me-1" />
            )}
            {t('LIST')}
          </Link>
        </div>
        {loading && (
          <div className={styles.LoadingButton}>
            <Spinner
              style={{
                color: 'var(--primary)',
              }}
              size="sm"
            />
          </div>
        )}
        <div
          className={styles.ButtonResetItalia}
          onClick={() => {
            map.getView().animate({
              center: fromLonLat(
                activeGeocontext?.centroid.coordinates as [number, number]
              ),
              zoom: activeGeocontext?.zoom_level,
              duration: 1000,
            })
          }}
        >
          <span style={{ fontSize: 25 }}>
            {unicodeToChar(activeGeocontext?.flag)}
          </span>
        </div>
        <div className={styles.ContainerButtons}>
          <div className={styles.SwitchContainerCluster}>
            <label className={styles.LabelSwitch}>Cluster</label>
            <ReactSwitch
              offColor="#D0DFE4"
              size={15}
              disabled={!switchCluster}
              checkedIcon={false}
              uncheckedIcon={false}
              onColor="#40BAEC"
              onChange={(checked) => {
                setFilters({
                  ...filters,
                  cluster_active: checked ? 'true' : '',
                })
              }}
              checked={filters.cluster_active === 'true'}
            />
          </div>
          <button
            className={styles.ButtonMyLocation}
            onClick={handleLocationClick}
          >
            <MyLocation />
          </button>
        </div>

        <div
          ref={popupRef}
          style={{
            position: 'absolute',
            top: '-12px',
            pointerEvents: 'none',
          }}
        >
          {infoMarker &&
            createPortal(
              <>
                <div
                  style={{
                    opacity: 1,
                    zIndex: 1,
                    pointerEvents: 'none',
                    backgroundColor:
                      infoMarker.pictures_count === 0
                        ? 'var(--tertiary)'
                        : infoMarker.pictures_count > 0 &&
                          infoMarker.pictures_count <= 10
                        ? 'var(--monumento-poche-foto)'
                        : 'var(--monumento-tante-foto)',
                  }}
                  className={styles.DetailMarker}
                >
                  <div>
                    <IconMonument
                      monument={{
                        in_contest: infoMarker.in_contest,
                        pictures_count: infoMarker.pictures_count,
                        app_category: infoMarker.app_category,
                      }}
                    />
                  </div>
                  <div className={styles.TitleMarker}>
                    {infoMarker.label.charAt(0).toUpperCase() +
                      infoMarker.label.slice(1)}
                  </div>

                  <div className={styles.TextMarker}>
                    <div>
                      <CameraTransparent />
                    </div>
                    <div className="ms-2 mt-1">{infoMarker.pictures_count}</div>
                  </div>
                  <div
                    className={styles.PinMarker}
                    style={{
                      borderTop:
                        '10px solid ' +
                        (infoMarker.pictures_count === 0
                          ? 'var(--tertiary)'
                          : infoMarker.pictures_count > 0 &&
                            infoMarker.pictures_count <= 10
                          ? 'var(--monumento-poche-foto)'
                          : 'var(--monumento-tante-foto)'),
                    }}
                  ></div>
                </div>
              </>,
              popup.getElement()
            )}
        </div>
        <div
          ref={markersClusterRef}
          style={{
            position: 'absolute',
            top: '-12px',
          }}
        >
          {markersCluster &&
            // create portal for cluster with list of monuments
            markersCluster.length > 1 &&
            createPortal(
              <>
                <div
                  style={{
                    opacity: 1,
                    zIndex: 1,
                    backgroundColor: 'white',
                  }}
                  className={styles.DetailMarkerCluster}
                >
                  {markersCluster.map((marker, index) => (
                    <div
                      key={index}
                      className={classNames(
                        `${styles.MarkerCluster} py-2 pointer`,
                        {
                          'border-bottom': index !== markersCluster.length - 1,
                        }
                      )}
                    >
                      <div
                        style={{
                          width: 20,
                        }}
                      >
                        <IconMonument
                          monument={{
                            in_contest: marker.in_contest,
                            pictures_count: marker.pictures_count,
                            app_category: marker.app_category,
                          }}
                        />
                      </div>
                      {
                        <Link
                          onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()

                            setDetail(marker.id)
                          }}
                          to={`/${i18n.language}/mappa?${new URLSearchParams({
                            search: filters.search,
                            municipality: filters.municipality,
                            app_category: filters.app_category,
                            in_contest: filters.in_contest,
                            only_without_pictures:
                              filters.only_without_pictures,
                            user_lat: filters.user_lat || '',
                            user_lon: filters.user_lon || '',
                            ordering: filters.ordering,
                            monument_id: String(marker.id),
                            only_disabled: filters.only_disabled,
                            monument_lat: filters.monument_lat || '',
                            monument_lon: filters.monument_lon || '',
                            cluster_active: filters.cluster_active,
                            geo_context:
                              filters.geo_context ||
                              String(getActiveGeocontext()?.id),
                            without_coordinates: filters.without_coordinates,
                          })}`}
                          className={`${styles.TitleMarkerCluster} avoid-clicks`}
                        >
                          {marker.label.charAt(0).toUpperCase() +
                            marker.label.slice(1)}
                        </Link>
                      }
                    </div>
                  ))}
                  <div
                    className={styles.PinMarker}
                    style={{
                      borderTop: '10px solid white',
                    }}
                  ></div>
                </div>
              </>,
              popupMarker.getElement()
            )}
        </div>
      </div>
    </div>
  )
}
