import { ReactComponent as ScrapingRed } from '../../../assets/scraping-red.svg'
import { ReactComponent as Close } from '../../../assets/close.svg'
import styles from './Scraping.module.css'
import { useTranslation } from 'react-i18next'
import { getActiveGeocontext } from '../../../utils'
import {
  useLastScrapingGeocontext,
  useStartScraping,
  useStopScraping,
} from '../../../hooks/geocontext'
import dayjs from 'dayjs'
import { useEffect } from 'react'

export default function Scraping({
  scrapingOpen,
  setScrapingOpen,
}: {
  scrapingOpen: boolean
  setScrapingOpen: (value: boolean) => void
}) {
  const { t } = useTranslation()
  const activeGeocontext = getActiveGeocontext()
  const lastScraping = useLastScrapingGeocontext(activeGeocontext?.id || 0)
  const {
    mutateAsync: startScraping,
    isLoading: isStartScrapingLoading,
    error: startScrapingError,
  } = useStartScraping(activeGeocontext?.id || 0)

  const {
    mutateAsync: stopScraping,
    isLoading: isStopScrapingLoading,
    error: stopScrapingError,
  } = useStopScraping(activeGeocontext?.id || 0)

  //polling
  useEffect(() => {
    const interval = setInterval(() => {
      lastScraping.refetch()
    }, 8000)
    return () => clearInterval(interval)
  }, [])

  return (
    <div className={styles.ScrapingModal}>
      <div className="w-100 h-100 d-flex justify-content-center position-relative">
        <div
          className={styles.HomeContent}
          style={{
            width: 532,
            transition: 'width 0.3s ease-in-out',
          }}
        >
          <div className={styles.Close} onClick={() => setScrapingOpen(false)}>
            <Close />
          </div>
          <div className={styles.Title}>
            <ScrapingRed className="me-2" /> Scraping
          </div>

          {!!lastScraping.data?.last_snapshot_done && (
            <>
              <div className="d-flex flex-column justify-content-between mt-4">
                <div className={styles.Desc}>
                  {t('LAST_SCRAPING_PERFORMED_ON')}{' '}
                  {dayjs(lastScraping.data?.last_snapshot_done?.updated).format(
                    'DD/MM/YYYY HH:mm'
                  )}
                </div>
                <div className={styles.Desc}>
                  {t('LAST_SCRAPING_MONUMENTS')}{' '}
                  {lastScraping.data?.last_snapshot_done?.total_monuments}
                </div>
              </div>

              {!!!lastScraping.data?.last_snapshot_running && (
                <div className="mt-2">
                  {lastScraping.data?.last_snapshot_done.query_status.map(
                    (status, index) => (
                      <div key={index} className={styles.Status}>
                        <div className={styles.StatusLabel}>{status.label}</div>
                        <div className={'ms-5'}>{status.total_monuments}</div>
                      </div>
                    )
                  )}
                </div>
              )}
            </>
          )}

          {!!!lastScraping.data?.last_snapshot_done && (
            <div className="d-flex align-items-center justify-content-between mt-4">
              <div className={styles.Desc}>{t('NO_SCRAPING_DONE')} </div>
            </div>
          )}

          {!!lastScraping.data?.last_snapshot_running && (
            <>
              <div className="d-flex flex-column justify-content-between mt-4">
                <div className={styles.Desc}>
                  {t('LAST_SCRAPING_RUNNING_STARTED')}{' '}
                  {dayjs(
                    lastScraping.data?.last_snapshot_running?.created
                  ).format('DD/MM/YYYY HH:mm')}
                </div>
                <div className={styles.Desc}>
                  {t('LAST_SCRAPING_RUNNING_STATUS')}{' '}
                  {lastScraping.data?.last_snapshot_running?.task_status}
                </div>
                <div className={styles.Desc}>
                  {t('LAST_SCRAPING_RUNNING_MONUMENTS')}{' '}
                  {lastScraping.data?.last_snapshot_running?.done_monuments} /{' '}
                  {lastScraping.data?.last_snapshot_running?.total_monuments}
                </div>
              </div>

              <div className="mt-2">
                {lastScraping.data?.last_snapshot_running.query_status.map(
                  (status, index) => (
                    <div key={index} className={styles.Status}>
                      <div className={styles.StatusLabel}>{status.label}</div>
                      <div className={'ms-5'}>{status.total_monuments}</div>
                    </div>
                  )
                )}
              </div>

              <div className="w-100 mt-2">
                <button
                  className="btn btn-danger w-100 btn-sm"
                  disabled={isStopScrapingLoading}
                  onClick={() =>
                    stopScraping().then(() => lastScraping.refetch())
                  }
                >
                  {t('STOP_SCRAPING')}
                </button>
                {!!stopScrapingError && (
                  <div className="text-danger mt-2">
                    {stopScrapingError.toString()}
                  </div>
                )}
              </div>
            </>
          )}

          {!!!lastScraping.data?.last_snapshot_running &&
            !!!lastScraping.data?.snapshot_queued && (
              <>
                <div className="d-flex align-items-center justify-content-between mt-4">
                  <div className={styles.Desc}>{t('NO_SCRAPING_RUNNING')} </div>
                </div>
                <div className="w-100 mt-2">
                  <button
                    className="btn btn-danger btn-sm w-100"
                    disabled={isStartScrapingLoading}
                    onClick={() =>
                      startScraping(true).then(() => lastScraping.refetch())
                    }
                  >
                    {t('RESTART_SCRAPING')}
                  </button>
                  {!!startScrapingError && (
                    <div className="text-danger mt-2">
                      {startScrapingError.toString()}
                    </div>
                  )}
                </div>
              </>
            )}

          {!!!lastScraping.data?.last_snapshot_running &&
            lastScraping.data?.snapshot_queued && (
              <>
                <div className="d-flex align-items-center justify-content-between mt-4">
                  <div className={styles.Desc}>{t('SNAPSHOT_QUEUED')} </div>
                </div>
              </>
            )}

          {/* <div className="d-flex align-items-center justify-content-between mt-4">
            <div className={styles.Desc}>
              {t('LAST_SCRAPING_PERFORMED_ON')}{' '}
              {dayjs(lastScraping.data?.updated).format('DD/MM/YYYY HH:mm')}
            </div>
            <div>
              <div className={styles.ButtonScraping}>
                {t('RESTART_SCRAPING')}
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  )
}
