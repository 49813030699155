import { useMemo, useRef, useState } from 'react'
import { ReactComponent as Close } from '../../../assets/close.svg'
import { ReactComponent as CloseSecondary } from '../../../assets/close-secondary.svg'
import { ReactComponent as ArrowRight } from '../../../assets/arrow-right.svg'
import { ReactComponent as Flag } from '../../../assets/flag.svg'
import { ReactComponent as Search } from '../../../assets/search.svg'
import { ReactComponent as CheckOrderingIcon } from '../../../assets/ordering-checked.svg'
import { ReactComponent as UncheckOrderingIcon } from '../../../assets/ordering-unchecked.svg'
import { useTranslation } from 'react-i18next'
import styles from './BlockFilters.module.css'
import { useComuni } from '../../../hooks/comuni'
import FiltersIcon from '../../Icons/FiltersIcon'
import classNames from 'classnames'
import ReactSwitch from 'react-switch'
import { getActiveGeocontext } from '../../../utils'
import { useAuthUser } from 'use-eazy-auth'

interface BlockFiltersProps {
  filtersOpen: boolean
  setFiltersOpen: (filtersOpen: boolean) => void
  setFilters: (filters: any) => void
  filters: Record<string, any>
  setComuneFilterCoords?: (coords: number[]) => void
}

export default function BlockFilters({
  filtersOpen,
  setFiltersOpen,
  setFilters,
  filters,
  setComuneFilterCoords,
}: BlockFiltersProps) {
  const [searchComune, setSearchComune] = useState<string>('')
  const [filterComuneOpen, setFilterComuneOpen] = useState<boolean>(false)
  const [filterCategoriaOpen, setFilterCategoriaOpen] = useState<boolean>(false)
  const { t } = useTranslation()
  const activeGeocontext = getActiveGeocontext()

  const filtersComuni = useMemo(() => {
    if (searchComune !== '') {
      return {
        q: searchComune,
      }
    } else {
      if (filters.municipality !== '') {
        return {
          q: searchComune,
          id: filters.municipality,
        }
      } else {
        return {}
      }
    }
  }, [searchComune, filters.municipality])

  const { data: comuni } = useComuni(activeGeocontext?.id, filtersComuni)

  const comuniFiltered = useMemo(() => {
    const searchTrimmed = searchComune.trimStart().trimEnd()
    if (searchTrimmed === '') {
      return []
    }
    const comuniFiltered = comuni?.filter((comune) =>
      comune.name.toLowerCase().includes(searchTrimmed.toLowerCase())
    )
    const comuniOrdered = comuniFiltered?.sort((a, b) => {
      const aStartWith = a.name.toLowerCase().startsWith(searchTrimmed)
      const bStartWith = b.name.toLowerCase().startsWith(searchTrimmed)
      if (aStartWith && !bStartWith) {
        return -1
      } else if (!aStartWith && bStartWith) {
        return 1
      } else {
        return a.name.length - b.name.length
      }
    })

    return comuniOrdered
  }, [searchComune, comuni])

  const parentRef = useRef<HTMLDivElement>(null)

  const [openComuni, setOpenComuni] = useState<boolean>(false)

  const isResetDisaable = useMemo(() => {
    return (
      filters.app_category === '' &&
      filters.municipality === '' &&
      filters.in_contest === 'true' &&
      filters.only_without_pictures === '' &&
      filters.without_coordinates === ''
    )
  }, [filters])

  const orderedCategories = useMemo(() => {
    if (activeGeocontext) {
      return activeGeocontext.app_categories.sort((a, b) =>
        a.order > b.order ? 1 : -1
      )
    }
    return []
  }, [activeGeocontext])

  const { user } = useAuthUser()
  const username = user ? user.username.replace('mw--', ' ') : ''

  return (
    <>
      <div
        className={styles.OverlayFilters}
        style={{
          opacity: filtersOpen ? 1 : 0,
          pointerEvents: filtersOpen ? 'all' : 'none',
          transition: 'all 0.5s ease-in-out',
        }}
        onClick={() => {
          setFiltersOpen(false)
        }}
      ></div>
      <div
        style={{
          transform: filtersOpen ? 'translateY(0)' : 'translateY(100%)',
          transition: 'all 0.5s ease-in-out',
        }}
        className={styles.ContainerFilters}
      >
        <div className={styles.TitleBlockFilters}>
          <div className="d-flex align-items-center">
            <FiltersIcon fill="var(--primary)" />
            <div className="ms-2">{t('FILTER')}</div>
          </div>
          <div>
            <Close onClick={() => setFiltersOpen(false)} />
          </div>
        </div>
        <div className={styles.ListFilters}>
          <div
            className={styles.Filter}
            onClick={() => {
              setFilterComuneOpen(!filterComuneOpen)
            }}
          >
            <div className={styles.FilterTitle}>{t('MUNICIPALITY')}</div>
            <div className="d-flex align-items-center">
              <div className={styles.FilterItem}>
                {filters.municipality !== '' ? (
                  <>
                    {
                      comuni?.find(
                        (comune) =>
                          String(comune.id) === String(filters.municipality)
                      )?.name
                    }
                    <CloseSecondary
                      className="ms-2"
                      onClick={(e) => {
                        e.stopPropagation()
                        setFilters({
                          ...filters,
                          municipality: '',
                        })
                      }}
                    />
                  </>
                ) : (
                  t('ALL')
                )}
              </div>
              <div className="ms-2">
                <ArrowRight />
              </div>
            </div>
          </div>
          <div
            className={styles.Filter}
            onClick={() => {
              setFilterCategoriaOpen(!filterCategoriaOpen)
            }}
          >
            <div className={styles.FilterTitle}>{t('CATEGORY')}</div>
            <div className="d-flex align-items-center">
              <div className={styles.FilterItem}>
                {filters.app_category !== '' ? (
                  <>
                    {t(
                      activeGeocontext?.app_categories.find(
                        (category) =>
                          String(category.id) === String(filters.app_category)
                      )?.name || ''
                    )}
                    <CloseSecondary
                      className="ms-2"
                      onClick={(e) => {
                        e.stopPropagation()
                        setFilters({
                          ...filters,
                          app_category: '',
                        })
                      }}
                    />
                  </>
                ) : (
                  t('ALL')
                )}
              </div>
              <div className="ms-2">
                <ArrowRight />
              </div>
            </div>
          </div>
          <div className={styles.Filter}>
            <div className={styles.FilterTitle}>
              {t('ALSO_MONUMENTS_OUT_OF_CONTEST')}
            </div>
            <div className="d-flex align-items-center">
              <div className={styles.FilterItem}>
                <ReactSwitch
                  offColor="#D0DFE4"
                  size={15}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onColor="#40BAEC"
                  onChange={(checked) => {
                    setFilters({
                      ...filters,
                      in_contest: checked ? '' : 'true',
                    })
                  }}
                  checked={filters.in_contest !== 'true'}
                />
              </div>
              <div className="ms-2">
                <ArrowRight />
              </div>
            </div>
          </div>
          <div className={styles.Filter}>
            <div className={styles.FilterTitle}>
              {t('ONLY_MONUMENTS_WITHOUT_PHOTOS')}
            </div>
            <div className="d-flex align-items-center">
              <div className={styles.FilterItem}>
                <ReactSwitch
                  offColor="#D0DFE4"
                  size={15}
                  checkedIcon={false}
                  uncheckedIcon={false}
                  onColor="#40BAEC"
                  onChange={(checked) => {
                    setFilters({
                      ...filters,
                      only_without_pictures: checked ? 'true' : '',
                    })
                  }}
                  checked={filters.only_without_pictures === 'true'}
                />
              </div>
              <div className="ms-2">
                <ArrowRight />
              </div>
            </div>
          </div>
          {location.pathname.indexOf('lista') !== -1 && (
            <div className={styles.Filter}>
              <div className={styles.FilterTitle}>
                {t('MONUMENTS_WITHOUT_COORDINATES')}
              </div>
              <div className="d-flex align-items-center">
                <div className={styles.FilterItem}>
                  <ReactSwitch
                    offColor="#D0DFE4"
                    size={15}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onColor="#40BAEC"
                    onChange={(checked) => {
                      setFilters({
                        ...filters,
                        without_coordinates: checked ? 'true' : '',
                      })
                    }}
                    checked={filters.without_coordinates === 'true'}
                  />
                </div>
                <div className="ms-2">
                  <ArrowRight />
                </div>
              </div>
            </div>
          )}
          {username &&
            user.admin_geo_contexts.includes(activeGeocontext?.id) && (
              <div className={styles.Filter}>
                <div className={styles.FilterTitle}>
                  {t('ENTRIES_DISABLED')}
                </div>
                <div className="d-flex align-items-center">
                  <ReactSwitch
                    offColor="#D0DFE4"
                    size={15}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onColor="#CF4130"
                    onChange={(checked) => {
                      setFilters({
                        ...filters,
                        only_disabled: checked ? 'true' : '',
                      })
                    }}
                    checked={filters.only_disabled === 'true'}
                  />
                </div>
              </div>
            )}
          <div className="d-flex mt-4 justify-content-end">
            <button
              className={classNames({
                [styles.ResetDisabled]: isResetDisaable,
                [styles.Reset]: !isResetDisaable,
              })}
              disabled={isResetDisaable}
              onClick={() => {
                setFilters({
                  app_category: '',
                  municipality: '',
                  in_contest: 'true',
                  only_without_pictures: '',
                  without_coordinates: '',
                })
              }}
            >
              {t('RESET')}
            </button>
          </div>
        </div>
      </div>
      <div
        className={styles.BlockFilterComune}
        style={{
          transform: filterComuneOpen ? 'translateX(0)' : 'translateX(-100%)',
          transition: 'all 0.5s ease-in-out',
          pointerEvents: filterComuneOpen ? 'all' : 'none',
        }}
      >
        <div className={styles.TitleBlockFilters}>
          <div className="d-flex align-items-center">
            <FiltersIcon fill="var(--primary)" />
            <div className="ms-2">{t('SEARCH_MUNICIPALITY')}</div>
          </div>
          <div>
            <Close onClick={() => setFilterComuneOpen(false)} />
          </div>
        </div>
        <div className={styles.InputSearchComune}>
          <input
            className={styles.InputSearchFieldComune}
            onChange={(e) => {
              setOpenComuni(true)
              setSearchComune(e.target.value)
            }}
          />
          <div className={styles.SearchIconSearch}>
            <Search />
          </div>
        </div>
        <div className={styles.ListComuni} ref={parentRef}>
          {openComuni &&
            searchComune.length > 0 &&
            comuniFiltered?.map((comune) => {
              return (
                <div
                  className={styles.FilterItemComune}
                  key={comune.code}
                  onClick={() => {
                    setFilters({ municipality: comune.id })
                    setFilterComuneOpen(false)
                    if (setComuneFilterCoords) {
                      const coords = comune.centroid.coordinates
                      setComuneFilterCoords(coords)
                    }
                    sessionStorage.removeItem('monument_id')
                  }}
                >
                  <Flag className="me-2" /> {comune.name}
                </div>
              )
            })}
        </div>
      </div>
      <div
        className={styles.BlockFilterCategoria}
        style={{
          transform: filterCategoriaOpen
            ? 'translateX(0)'
            : 'translateX(-100%)',
          transition: 'all 0.5s ease-in-out',
          pointerEvents: filterCategoriaOpen ? 'all' : 'none',
        }}
      >
        <div className={styles.TitleBlockFilters}>
          <div className="d-flex align-items-center">
            <FiltersIcon fill="var(--primary)" />
            <div className="ms-2">{t('CHOOSE_CATEGORY')}</div>
          </div>
          <div>
            <Close onClick={() => setFilterCategoriaOpen(false)} />
          </div>
        </div>
        <div className={styles.ListOrderingItems}>
          <div
            className={classNames(styles.OrderingItem, {
              [styles.OrderingItemActive]: filters.app_category === '',
            })}
            onClick={() => {
              setFilters({
                ...filters,
                app_category: '',
              })
              setFilterCategoriaOpen(false)
            }}
          >
            <div>
              {filters.app_category === '' ? (
                <CheckOrderingIcon />
              ) : (
                <UncheckOrderingIcon />
              )}
            </div>
            <div className={styles.OrderingItemTitle}>
              {t('ALL_CATEGORIES')}
            </div>
          </div>
          {orderedCategories.map((category) => (
            <div
              key={category.id}
              className={classNames(styles.OrderingItem, {
                [styles.OrderingItemActive]:
                  String(filters.app_category) === String(category.id),
              })}
              onClick={() => {
                setFilters({
                  ...filters,
                  app_category: category.id,
                })
                setFilterCategoriaOpen(false)
              }}
            >
              <div>
                {String(filters.app_category) === String(category.id) ? (
                  <CheckOrderingIcon />
                ) : (
                  <UncheckOrderingIcon />
                )}
              </div>
              <div className={styles.OrderingItemTitle}>{t(category.name)}</div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
