import classNames from 'classnames'
import styles from './IconMonument.module.css'
import { getActiveGeocontext } from '../../utils'
import { useMemo } from 'react'

interface Props {
  monument: {
    app_category: number
    pictures_count: number
    in_contest: boolean
  }
}

export default function IconMonument({ monument }: Props) {
  const photosNumber = monument.pictures_count ?? 0
  const activeGeocontext = getActiveGeocontext()

  const iconMonument = useMemo(() => {
    if (activeGeocontext) {
      const category = activeGeocontext.app_categories.find(
        (category) => category.id === monument.app_category
      )
      if (monument.in_contest) {
        return category?.icon.icon_white
      }
      return category?.icon.icon_black
    }
  }, [activeGeocontext, monument.app_category])

  return (
    <div
      className={classNames(styles.IconMonument, {
        [styles.NoPhotos]: photosNumber === 0,
        [styles.OneToTenPhoto]: photosNumber > 0 && photosNumber <= 10,
        [styles.MoreThenTenPhoto]: photosNumber > 10,
      })}
    >
      <img
        src={iconMonument}
        alt={
          photosNumber === 0
            ? 'Monumento senza foto'
            : photosNumber > 0 && photosNumber <= 10
            ? 'Monumento con da 1 a 10 foto'
            : 'Monumento con più di 10 foto'
        }
        width="12"
      />
    </div>
  )
}
