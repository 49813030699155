import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Layout from '../../../components/Mobile/Layout'
import {
  enableMonument,
  useMonument,
  disableMonument,
  updateMonument,
} from '../../../hooks/monuments'
import {
  getActiveGeocontext,
  isBrowserMobile,
  parseSmartSlug,
  smartSlug,
} from '../../../utils'
import styles from './Detail.module.css'
import { ReactComponent as Bell } from '../../../assets/bell.svg'
import { ReactComponent as CameraDark } from '../../../assets/camera-dark.svg'
import { ReactComponent as CameraDarkBig } from '../../../assets/camera-dark-big.svg'
import { ReactComponent as Camera } from '../../../assets/camera.svg'
import { ReactComponent as Direction } from '../../../assets/direction.svg'
import { ReactComponent as SmileBad } from '../../../assets/smile-bad.svg'
import { ReactComponent as Reasonator } from '../../../assets/reasonetor.svg'
import { ReactComponent as ArrowRight } from '../../../assets/arrow-right.svg'
import { ReactComponent as Wikidata } from '../../../assets/wikidata.svg'
import { ReactComponent as Wikipedia } from '../../../assets/wikipedia.svg'
import { ReactComponent as NoCoordinates } from '../../../assets/no-coordinates.svg'
import { ReactComponent as InfoVedute } from '../../../assets/info-vedute.svg'
import { ReactComponent as InfoVeduteDark } from '../../../assets/info-vedute-dark.svg'
import { ReactComponent as Close } from '../../../assets/close.svg'
import { ReactComponent as ArrowLeftSlideShow } from '../../../assets/left-slideshow-arrow.svg'
import { ReactComponent as ArrowRightSlideShow } from '../../../assets/right-slideshow-arrow.svg'
import { ReactComponent as License } from '../../../assets/icon-license.svg'
import { useTranslation } from 'react-i18next'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperClass, { Virtual } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import { Fragment, Suspense, useEffect, useMemo, useRef, useState } from 'react'
import IconMonument from '../../../components/IconMonument'
import SlideShow from '../../../components/Mobile/SlideShow'
import { Feature, Map as MapOl, View } from 'ol'
import { fromLonLat } from 'ol/proj'
import TileLayer from 'ol/layer/Tile'
import OSM from 'ol/source/OSM'
import MapIcon from '../../../components/Icons/MapIcon'
import VectorTileLayer from 'ol/layer/VectorTile'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { Point } from 'ol/geom'
import Style from 'ol/style/Style'
import Icon from 'ol/style/Icon'
import getMarkerMap from '../../../components/MarkerMap/MarkerMap'
import VeduteInsiemeModal from '../../../components/Mobile/VeduteInsiemeModal'
import LangLink from '../../../components/LangLink'
import { Geocontext, Monument } from '../../../types'
import classNames from 'classnames'
import { useQsFilters } from '../../../hooks/filters'
import dayjs from 'dayjs'
import { useAuthUser } from 'use-eazy-auth'
import { API_URL } from '../../../const'
import BlockUploadFormik from '../../../components/Mobile/BlockUpload/BlockUpload'
import { toast } from 'react-toastify'
import { useQueryClient } from '@tanstack/react-query'
import { Modal, Spinner } from 'react-bootstrap'
import { useDonationContext } from '../../../context/DonationContext'
import { set } from 'lodash'

interface Props {
  monumentId?: number
  setDetail?: (monument: number | null) => void
  isDesktop?: boolean
}

interface DetailBlockProps {
  monument: Monument | null
  setDetail?: (monument: number | null) => void
  isDesktop?: boolean
  activeGeocontext: Geocontext | undefined
}

const getFilters = (params: URLSearchParams) => ({
  search: params.get('search') ?? '',
  municipality: params.get('municipality') ?? '',
  ordering: params.get('ordering') ?? 'label',
  in_contest: params.get('in_contest') ?? 'true',
  only_without_pictures: params.get('only_without_pictures') ?? '',
  app_category: params.get('app_category') ?? '',
  user_lat: Number(params.get('user_lat')) ?? '',
  user_lon: Number(params.get('user_lon')) ?? '',
  monument_lat: Number(params.get('monument_lat')) ?? '',
  monument_lon: Number(params.get('monument_lon')) ?? '',
  map_zoom: Number(params.get('map_zoom')) ?? '',
  map_lat: Number(params.get('map_lat')) ?? '',
  map_lon: Number(params.get('map_lon')) ?? '',
  monument_id: Number(params.get('monument_id')) ?? '',
  geo_context: params.get('geo_context') ?? '',
  cluster_active: params.get('cluster_active') ?? '',
  only_disabled: params.get('only_disabled') ?? '',
  without_coordinates: params.get('without_coordinates') ?? '',
})

function DetailBlock({
  monument,
  setDetail,
  isDesktop,
  activeGeocontext,
}: DetailBlockProps) {
  const [showAllImages, setShowAllImages] = useState(false)
  const [slideShowActive, setSlideShowActive] = useState(0)
  const [infoSlideSlideShow, setInfoSlideSlideShow] = useState(false)
  const [slideActive, setSlideActive] = useState(0)
  const [slideGroup12, setSlideGroup12] = useState(0)
  const { t, i18n } = useTranslation()
  const inputFileRef = useRef<HTMLInputElement>(null)
  const swiperRef = useRef<SwiperClass>()
  const swiperBlock12 = useRef<SwiperClass>()
  const mapElement = useRef<HTMLDivElement>(null)
  const [map, setMap] = useState<MapOl | null>(null)
  const [imageUpload, setImageUpload] = useState<FileList | null>(null)
  const [showModalUpload, setShowModalUpload] = useState(false)
  const [veduteInsiemeOpen, setVeduteInsiemeOpen] = useState(false)
  const { filters, setFilters } = useQsFilters(getFilters)
  const isMobile = isBrowserMobile()
  const [showLicenseModal, setShowLicenseModal] = useState(false)

  const categoryApp = useMemo(() => {
    return activeGeocontext?.app_categories.find(
      (category) => category.id === monument?.app_category
    )
  }, [monument])

  const picturesToUse = useMemo(() => {
    return monument?.pictures.sort((a, b) => {
      if (a.is_relevant && !b.is_relevant) return -1
      if (!a.is_relevant && b.is_relevant) return 1
      return a.image_date > b.image_date ? -1 : 1
    })
  }, [monument])

  const groupsOf12Pictures = picturesToUse?.reduce((acc, curr, index) => {
    const groupIndex = isMobile
      ? Math.floor(index / 12)
      : Math.floor(index / 18)
    if (!acc[groupIndex]) {
      acc[groupIndex] = []
    }
    acc[groupIndex].push(curr)
    return acc
  }, [] as any[])

  const inContestMonument = useMemo(() => {
    return monument?.in_contest
  }, [monument])

  useEffect(() => {
    if (!mapElement.current) return
    if (!monument) return
    if (!categoryApp) return

    const initialMap = new MapOl({
      interactions: [],
      target: mapElement.current,
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
        new VectorTileLayer({}),
      ],
      controls: [],
      view: new View({
        center: fromLonLat(
          monument?.position?.coordinates
            ? monument?.position.coordinates
            : [12.56738, 41.87194]
        ),
        zoom: 13,
      }),
      moveTolerance: 10,
    })

    const iconFeature = new Feature({
      geometry: new Point(
        fromLonLat(
          monument?.position?.coordinates
            ? monument?.position.coordinates
            : [12.56738, 41.87194]
        )
      ),
    })

    const iconStyle = new Style({
      image: new Icon({
        src: getMarkerMap({
          monument: monument!,
          category: categoryApp,
        }),
        scale: 0.2,
      }),
    })

    iconFeature.setStyle(iconStyle)

    const vectorSource = new VectorSource({
      features: [iconFeature],
    })

    const vectorLayer = new VectorLayer({
      source: vectorSource,
    })

    initialMap.addLayer(vectorLayer)

    setMap(initialMap)

    return () => initialMap.setTarget(undefined as unknown as HTMLElement)
  }, [monument, categoryApp])

  const { slug } = useParams()
  const navigate = useNavigate()
  const location = useLocation()

  const { user } = useAuthUser()

  useEffect(() => {
    if (inputFileRef.current) {
      inputFileRef.current.value = ''
    }
  }, [showModalUpload])

  useEffect(() => {
    if (monument) {
      setSlideActive(0)
      if (swiperRef.current) {
        swiperRef.current.activeIndex = 0
      }
      setSlideGroup12(0)
      if (swiperBlock12.current) {
        swiperBlock12.current.activeIndex = 0
      }
      setSlideShowActive(0)
    }
  }, [monument])

  const { pathname, search } = useLocation()
  const { token } = useAuthUser()

  const addressCorrectFormatted = useMemo(() => {
    if (!monument) return ''
    const address = monument.address
    if (!address) return ''
    const addressWithoutCap = address.replace(/\d{5}/, '')
    const addressWithoutMunicipalityLabel = addressWithoutCap.replace(
      monument.municipality_label,
      ''
    )
    const addressWithoutProvinceLabel = addressWithoutMunicipalityLabel.replace(
      monument.province_label,
      ''
    )
    const addressOnlyWithLettersNumbersAndComma =
      addressWithoutProvinceLabel.replace(/[^A-Za-zÀ-ÖØ-öø-ÿ, ]/g, '')
    const addressFinal = addressOnlyWithLettersNumbersAndComma.split(',')
    if (addressFinal.length === 1) return addressFinal[0].replace(',', '')
    const addressSplittedSecondPartWithoutLetters = addressFinal[1].replace(
      /[^0-9]/g,
      ''
    )
    if (!addressSplittedSecondPartWithoutLetters)
      return addressFinal[0].replace(',', '')
    const addressToReturn = `${addressFinal[0]}, ${addressSplittedSecondPartWithoutLetters}`
    return addressToReturn
  }, [monument])

  const queryClient = useQueryClient()

  const username = user ? user.username.replace('mw--', ' ') : ''

  const appCategory = useMemo(() => {
    return activeGeocontext?.app_categories.find(
      (category) => category.id === monument?.app_category
    )
  }, [monument])

  const activeContest = useMemo(() => {
    if (!activeGeocontext) return null
    if (!activeGeocontext.next_contest) return null
    if (activeGeocontext.next_contest.active)
      return activeGeocontext.next_contest
    return null
  }, [activeGeocontext])

  const anyContest = useMemo(() => {
    return activeContest
  }, [activeContest])

  const uploadCategories = useMemo(() => {
    if (monument) {
      const categories =
        anyContest && monument.in_contest
          ? monument.categories_urls?.wlm_categories ?? []
          : monument.categories_urls?.non_wlm_categories ?? []

      return categories
    }
    return []
  }, [monument])

  const [uploadWizard, setUploadWizard] = useState<boolean | object>(false)

  const descriptionForWizard = useMemo(() => {
    if (!monument) return ''
    return `${
      monument.label.charAt(0).toUpperCase() + monument.label.slice(1)
    } {{Load via app ${
      activeGeocontext?.app_domain
    }|year=${dayjs().year()}|source=wizard}}`
  }, [monument])

  const [updatingMonument, setUpdateMonument] = useState(false)

  return (
    <>
      {monument?.disabled && <div className={styles.BgDisabled}></div>}
      <div
        className={classNames({
          [styles.DettaglioContainer]: !isDesktop,
          [styles.DettaglioContainerDesktop]: isDesktop,
        })}
      >
        <div
          className={classNames({
            [styles.DettaglioInfoCard]: !isDesktop,
            [styles.DettaglioInfoCardDesktop]: isDesktop,
          })}
        >
          {isDesktop && setDetail && (
            <div className={styles.Close}>
              <Close
                onClick={() => {
                  if (slug) {
                    setDetail(null)
                    // sessionStorage.removeItem('monument_id')
                    if (location.pathname.indexOf('lista') > -1) {
                      navigate(
                        `/${i18n.language}/lista/?${new URLSearchParams({
                          search: '',
                          municipality: filters.municipality,
                          app_category: filters.app_category,
                          in_contest: filters.in_contest,
                          only_without_pictures: filters.only_without_pictures,
                          user_lat: String(filters.user_lat),
                          user_lon: String(filters.user_lon),
                          geo_context: filters.geo_context,
                          cluster_active: filters.cluster_active,
                          only_disabled: filters.only_disabled,
                          without_coordinates: filters.without_coordinates,
                        })}`,

                        { replace: true }
                      )
                    } else {
                      navigate(
                        `/${i18n.language}/mappa/?${new URLSearchParams({
                          search: '',
                          municipality: filters.municipality,
                          app_category: filters.app_category,
                          in_contest: filters.in_contest,
                          only_without_pictures: filters.only_without_pictures,
                          user_lat: String(filters.user_lat),
                          user_lon: String(filters.user_lon),
                          geo_context: filters.geo_context,
                          cluster_active: filters.cluster_active,
                          only_disabled: filters.only_disabled,
                        })}`,

                        { replace: true }
                      )
                    }
                  } else {
                    // sessionStorage.removeItem('monument_id')
                    setFilters({
                      ...filters,
                      monument_id: undefined,
                      monument_lat: undefined,
                      monument_lon: undefined,
                    })
                    setDetail(null)
                  }
                }}
              />
            </div>
          )}
          <button
            onClick={() => {
              if (user) {
                inputFileRef.current?.click()
              } else {
                setShowLicenseModal(true)
              }
            }}
            disabled={monument?.disabled}
            className={styles.ButtonFixedCaricaFoto}
          >
            <CameraDark className="me-2" width={14} />
            {user ? t('UPLOAD_PHOTO') : t('LOGIN_AND_UPLOAD_PHOTOS')}
          </button>

          {inContestMonument ? (
            <div className={styles.PresenzaInConcorso}>
              <Bell className="me-2" />
              {appCategory?.is_municipality
                ? t('THE_MUNICIPALITY_IS_PART_OF_THE_CONTEST')
                : t('THE_MONUMENT_IS_PART_OF_THE_CONTEST')}
            </div>
          ) : (
            <div
              style={{
                height: 40,
              }}
            ></div>
          )}

          {monument && picturesToUse!.length > 0 ? (
            <>
              <Swiper
                onSwiper={(swiper) => {
                  swiperRef.current = swiper
                }}
                key={monument.id}
                onSlideChange={(swiper) => {
                  setSlideActive(swiper.activeIndex)
                }}
                spaceBetween={10}
                className={styles.Swiper}
                virtual
                modules={[Virtual]}
              >
                {picturesToUse?.map((picture, k) => (
                  <SwiperSlide
                    onClick={() => {
                      setShowAllImages(true)
                      setSlideShowActive(k)
                    }}
                    key={k}
                  >
                    <div
                      className={styles.BlockImage}
                      style={{
                        backgroundImage: `url("${picture.image_url}?width=700")`,
                      }}
                    >
                      <div className={styles.BlockImageOverlay}>
                        <div className="d-flex align-items-center">
                          <div className={styles.BlockImageOverlayText}>
                            {picture.data?.Artist && (
                              <a
                                href={
                                  'https://commons.wikimedia.org/wiki/User:' +
                                  String(picture.data?.Artist)
                                    .replace(/(<([^>]+)>)/gi, '')
                                    .replace(' ', '_')
                                    .split('(')[0]
                                }
                                onClick={(e) => {
                                  e.stopPropagation()
                                }}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <div
                                  className={styles.BlockImageOverlayTextArtist}
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      String(picture.data?.Artist)
                                        .replace(/(<([^>]+)>)/gi, '')
                                        .split('(')[0] || '',
                                  }}
                                ></div>
                              </a>
                            )}
                          </div>
                          <div>
                            <div className={styles.BlockImageOverlayTextDate}>
                              {dayjs(picture.data?.DateTime).format(
                                'DD/MM/YYYY'
                              )}
                            </div>
                          </div>
                        </div>
                        {picture.data?.License && (
                          <div className={styles.CreditsImage}>
                            {picture.data?.License}
                          </div>
                        )}
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
              {monument.pictures_count > 1 && (
                <div className={styles.PaginationContainer}>
                  <ArrowLeftSlideShow
                    onClick={() => {
                      if (slideActive > 0) {
                        swiperRef.current?.slidePrev()
                      }
                    }}
                    className={classNames('me-3', {
                      pointer: slideActive > 0,
                    })}
                    fill={
                      slideActive > 0
                        ? 'var(--primary)'
                        : 'var(--colori-neutri-gray-2)'
                    }
                  />
                  <div className={styles.CurrentSlide}>
                    {slideActive + 1} / {monument?.pictures_count}
                  </div>
                  <ArrowRightSlideShow
                    onClick={() => {
                      if (slideActive < monument?.pictures_count - 1) {
                        swiperRef.current?.slideNext()
                      }
                    }}
                    className={classNames('ms-3', {
                      pointer: slideActive < monument?.pictures_count - 1,
                    })}
                    fill={
                      slideActive < monument?.pictures_count - 1
                        ? 'var(--primary)'
                        : 'var(--colori-neutri-gray-2)'
                    }
                  />
                </div>
              )}
            </>
          ) : (
            <div className={styles.BoxNoImages}>
              <div>{t('STILL_NO_PHOTOS')}</div>
              <div>
                <SmileBad className="mt-2" />
              </div>
            </div>
          )}
          <button
            onClick={() => {
              if (user) {
                inputFileRef.current?.click()
              } else {
                setShowLicenseModal(true)
              }
            }}
            disabled={monument?.disabled}
            className={styles.ButtonCaricaFoto}
          >
            {user ? t('UPLOAD_PHOTO') : t('LOGIN_AND_UPLOAD_PHOTOS')}
          </button>

          <div className={styles.CardInfoMonument}>
            <div className="d-flex justify-content-between w-100">
              <div className="d-flex">
                {monument && <IconMonument monument={monument} />}
                <div className="ms-2">
                  <div>
                    {monument && (
                      <div className={styles.MonumentTitle}>
                        {monument?.label.charAt(0).toUpperCase() +
                          monument?.label.slice(1)}
                      </div>
                    )}
                    {monument?.municipality_label &&
                      !appCategory?.is_municipality && (
                        <span className={styles.Comune}>
                          {monument?.municipality_label} (
                          {monument?.province_label})
                        </span>
                      )}
                    {addressCorrectFormatted &&
                      monument &&
                      appCategory?.is_municipality && (
                        <div className={styles.Comune}>
                          {addressCorrectFormatted}
                        </div>
                      )}
                    {monument?.location &&
                      monument.location !== monument.municipality_label &&
                      appCategory?.is_municipality && (
                        <div className={styles.Comune}>
                          Loc. {monument?.location}
                        </div>
                      )}
                    {appCategory?.is_municipality && (
                      <div className="w-100 d-flex justify-content-between align-items-center">
                        <div className={styles.ComuneVedute}>
                          {t('OVERVIEW_OF_THE_MUNICIPALITY_OF')}{' '}
                          {monument?.label}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className={styles.PicturesCount}>
                <div className="d-flex align-items-center">
                  {monument?.pictures_count} <Camera className="ms-1" />
                </div>
                {appCategory?.is_municipality && (
                  <div
                    className="pointer"
                    onClick={() => {
                      setVeduteInsiemeOpen(!veduteInsiemeOpen)
                    }}
                  >
                    {veduteInsiemeOpen ? (
                      <InfoVeduteDark className="me-2" />
                    ) : (
                      <InfoVedute className="me-2" />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          {!isMobile && veduteInsiemeOpen && appCategory?.is_municipality && (
            <div className={styles.VeduteInsieme}>
              <div className={styles.VeduteInsiemeTitle}>
                {t('OVERVIEW_OF_THE_MUNICIPALITY')}
              </div>
              <div className={styles.VeduteInsiemeText}>
                {t('OVERVIEW_OF_THE_MUNICIPALITY_TEXT')}
              </div>
            </div>
          )}
          {monument &&
            monument?.parent_monuments &&
            monument?.parent_monuments?.length > 0 && (
              <div className={styles.CardParent}>
                <div className={styles.CardParentTitle}>
                  {t('THIS_MONUMENT_IS_PART_OF')}
                </div>
                {monument.parent_monuments.map((parent, i) => (
                  <div
                    key={i}
                    className={classNames('pointer', {
                      [styles.MonumentCard]: true,
                    })}
                    onClick={() => {
                      if (isDesktop) {
                        if (setDetail) {
                          setDetail(parent.id)
                        }
                      } else {
                        sessionStorage.setItem(
                          'monument',
                          JSON.stringify(parent)
                        )
                        navigate(
                          `/${i18n.language}/lista/${smartSlug(
                            parent.id,
                            parent.label
                          )}?${new URLSearchParams({
                            search: filters.municipality ? filters.search : '',
                            municipality: filters.municipality,
                            app_category: filters.app_category,
                            in_contest: filters.in_contest,
                            only_without_pictures:
                              filters.only_without_pictures,
                            user_lat: String(filters.user_lat),
                            user_lon: String(filters.user_lon),
                            ordering: filters.ordering,
                            monument_lat: String(filters.monument_lat) || '',
                            monument_lon: String(filters.monument_lon) || '',
                            geo_context: filters.geo_context,
                            without_coordinates: filters.without_coordinates,
                            only_disabled: filters.only_disabled,
                          })}`
                        )
                      }
                    }}
                  >
                    <div className="d-flex">
                      <div>
                        <IconMonument monument={parent} />
                      </div>
                      <div className="ms-2">
                        <div className={styles.MonumentTitle}>
                          {parent.label.charAt(0).toUpperCase() +
                            parent.label.slice(1)}
                        </div>
                        <div className={styles.Comune}>
                          {parent.municipality_label}

                          {parent.location &&
                            parent.location !== parent.municipality_label && (
                              <div>Loc: {parent.location}</div>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center flex-column">
                      <div className={styles.PicturesCount}>
                        <div className="d-flex align-items-center">
                          <div>{parent.pictures_count}</div>
                          <Camera className="ms-2" />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}

          {monument &&
            monument.children_monuments &&
            monument.children_monuments.length > 0 && (
              <div className={styles.CardChildren}>
                <div className={styles.CardChildrenTitle}>
                  {t('THEY_ARE_PART_OF_THIS_MONUMENT')}
                </div>
                {monument.children_monuments.map((child, i) => (
                  <div
                    key={i}
                    className={classNames('pointer', {
                      [styles.MonumentCard]: true,
                    })}
                    onClick={() => {
                      if (isDesktop) {
                        if (setDetail) {
                          setDetail(child.id)
                        }
                      } else {
                        sessionStorage.setItem(
                          'monument',
                          JSON.stringify(child)
                        )
                        navigate(
                          `/${i18n.language}/lista/${smartSlug(
                            child.id,
                            child.label
                          )}?${new URLSearchParams({
                            search: filters.municipality ? filters.search : '',
                            municipality: filters.municipality,
                            app_category: filters.app_category,
                            in_contest: filters.in_contest,
                            only_without_pictures:
                              filters.only_without_pictures,
                            user_lat: String(filters.user_lat),
                            user_lon: String(filters.user_lon),
                            ordering: filters.ordering,
                            monument_lat: String(filters.monument_lat) || '',
                            monument_lon: String(filters.monument_lon) || '',
                            geo_context: filters.geo_context,
                            without_coordinates: filters.without_coordinates,
                            only_disabled: filters.only_disabled,
                          })}`
                        )
                      }
                      // setFilters({
                      //   ...filters,
                      //   monument_id: child.id,
                      //   monument_lat: child.position?.coordinates[1],
                      //   monument_lon: child.position?.coordinates[0],
                      // })
                    }}
                  >
                    <div className="d-flex">
                      <div>
                        <IconMonument monument={child} />
                      </div>
                      <div className="ms-2">
                        <div className={styles.MonumentTitle}>
                          {child.label.charAt(0).toUpperCase() +
                            child.label.slice(1)}
                        </div>
                        <div className={styles.Comune}>
                          {child.municipality_label}
                          {child.location &&
                            child.location !== child.municipality_label && (
                              <div>Loc: {child.location}</div>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center flex-column">
                      <div className={styles.PicturesCount}>
                        <div className="d-flex align-items-center">
                          <div>{child.pictures_count}</div>
                          <Camera className="ms-2" />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
        </div>

        {monument && monument?.pictures_count > 0 && (
          <div className={styles.CardImages}>
            <div className={styles.ImmaginiWlmTitle}>{t('WLM_IMAGES')}</div>
            <Swiper
              className={styles.Swiper}
              onSwiper={(swiper) => {
                swiperBlock12.current = swiper
              }}
              virtual
              modules={[Virtual]}
              key={monument.id + 'gruped'}
              onSlideChange={(swiper) => {
                setSlideGroup12(swiper.activeIndex)
              }}
            >
              {groupsOf12Pictures &&
                groupsOf12Pictures.map((group, index) => (
                  <Fragment key={index}>
                    {group.length > 0 && (
                      <SwiperSlide key={index}>
                        <div className={styles.ContainerImages}>
                          {group.map((picture: any, k: number) => (
                            <div
                              key={k}
                              className={styles.Image}
                              onClick={() => {
                                setShowAllImages(true)
                                setSlideShowActive(
                                  k + index * (isMobile ? 12 : 18)
                                )
                              }}
                              style={{
                                backgroundImage: `url("${picture.image_url}?width=300")`,
                              }}
                            ></div>
                          ))}
                        </div>
                      </SwiperSlide>
                    )}
                  </Fragment>
                ))}
            </Swiper>
            {groupsOf12Pictures && groupsOf12Pictures.length > 1 && (
              <div className={styles.PaginationContainer}>
                <ArrowLeftSlideShow
                  onClick={() => {
                    if (slideGroup12 > 0) {
                      swiperBlock12.current?.slidePrev()
                    }
                  }}
                  className={classNames('me-3', {
                    pointer: slideGroup12 > 0,
                  })}
                  fill={
                    slideGroup12 > 0
                      ? 'var(--primary)'
                      : 'var(--colori-neutri-gray-2)'
                  }
                />
                <div className={styles.CurrentSlide}>
                  {slideGroup12 + 1} / {groupsOf12Pictures?.length}
                </div>
                <ArrowRightSlideShow
                  onClick={() => {
                    if (slideGroup12 < groupsOf12Pictures?.length - 1) {
                      swiperBlock12.current?.slideNext()
                    }
                  }}
                  className={classNames('ms-3', {
                    pointer: slideGroup12 < groupsOf12Pictures?.length - 1,
                  })}
                  fill={
                    slideGroup12 < groupsOf12Pictures?.length - 1
                      ? 'var(--primary)'
                      : 'var(--colori-neutri-gray-2)'
                  }
                />
              </div>
            )}
            <a
              target={'_blank'}
              href={
                monument.data.commonsCat
                  ? 'https://commons.wikimedia.org/wiki/Category:' +
                    monument.data.commonsCat
                  : 'https://commons.wikimedia.org/w/index.php?title=Special:Search&limit=500&offset=0&profile=default&search="' +
                    monument?.wlm_n +
                    '"'
              }
              className={styles.ButtonShowAllImages}
            >
              {t('SEE_ALL_ON_WIKIMEDIA_COMMONS')}
            </a>
          </div>
        )}
        <div className={styles.MapContainer}>
          <div className={styles.Map} ref={mapElement}></div>
          {monument?.position?.coordinates && (
            <button
              className={styles.GuardaInMappa}
              onClick={() => {
                navigate(
                  `/${i18n.language}/mappa?monument_lat=${
                    monument?.position?.coordinates[1]
                  }&monument_lon=${
                    monument?.position?.coordinates[0]
                  }&zoom=16&${new URLSearchParams({
                    only_without_pictures: filters.only_without_pictures,
                    in_contest: filters.in_contest,
                    app_category: filters.app_category,
                    search: filters.search,
                    municipality: filters.municipality,
                    user_lat: String(filters.user_lat),
                    user_lon: String(filters.user_lon),
                    geo_context: filters.geo_context,
                    cluster_active: filters.cluster_active,
                    only_disabled: filters.only_disabled,
                  }).toString()}`
                )
              }}
            >
              <MapIcon /> {t('SEE_IN_MAP')}
            </button>
          )}
          <div>
            {monument?.position?.coordinates && (
              <a
                className={styles.Direction}
                href={`https://www.openstreetmap.org/directions?engine=graphhopper_car&route=${monument?.position?.coordinates[1]}%2C${monument?.position?.coordinates[0]}%3B%3B&#map=16/${monument?.position?.coordinates[1]}/${monument?.position?.coordinates[0]}`}
                target="_blank"
              >
                <Direction />
              </a>
            )}
          </div>
          {!monument?.position?.coordinates && (
            <div className={styles.NoMap}>
              <div>
                <NoCoordinates />
              </div>
              <div className={styles.NoCoordinatesTitle}>
                {t('NO_COORDINATES')}
              </div>
              <div className={styles.NoCoordinatesDescription}>
                {t('THIS_MONUMENT_HAS_NO_COORDINATES')}
              </div>
            </div>
          )}
        </div>
        {monument && appCategory?.is_municipality && (
          <div className={styles.CardMonumentiComune}>
            <div className={styles.IMonumentiDelComune}>
              {t('MONUMENTS_OF_THE_MUNICIPALITY')}
            </div>
            <div className={styles.MonumentiComune}>
              {monument.counts_comune_by_app_category.map(
                (type, i) =>
                  type.app_category__name && (
                    <LangLink
                      key={i}
                      className="no-link"
                      to={
                        '/lista/?app_category=' +
                        type.app_category__id +
                        '&municipality=' +
                        monument?.q_number +
                        '&in_contest='
                      }
                    >
                      <div key={i} className={styles.MonumentiComuneItem}>
                        <div className={styles.MonumentiComuneItemTitle}>
                          <img
                            src={
                              activeGeocontext?.app_categories.find(
                                (category) =>
                                  category.name === type.app_category__name
                              )?.icon.icon_color
                            }
                            alt={type.app_category__name}
                            width={14}
                          />
                          <span className="ms-2">
                            {type.app_category__name}
                          </span>
                        </div>
                        <div className={styles.MonumentiComuneItemValue}>
                          {type.count}
                        </div>
                      </div>
                    </LangLink>
                  )
              )}
            </div>
            <div className="w-100">
              <LangLink
                className="no-link"
                to={
                  '/lista/?municipality=' +
                  monument?.municipality +
                  '&in_contest=' +
                  '&geo_context=' +
                  filters.geo_context
                }
              >
                <div className={styles.ButtonShowMonumenti}>
                  {t('SEE_MONUMENTS')}
                </div>
              </LangLink>
            </div>
          </div>
        )}
        <div className={styles.CardExternalLinks}>
          <div className={styles.ExternalLinksTitle}>{t('EXTERNAL_LINKS')}</div>
          <a
            className="no-link"
            target={'_blank'}
            rel="noreferrer"
            href={`https://reasonator.toolforge.org/?lang=${i18n.language}&q=${monument?.q_number}`}
          >
            <div className={styles.ExternalLink}>
              <div>
                <Reasonator className="me-1" /> Reasonator
              </div>
              <div>
                <ArrowRight />
              </div>
            </div>
          </a>
          <a
            className="no-link"
            target={'_blank'}
            rel="noreferrer"
            href={`https://www.wikidata.org/wiki/${monument?.q_number}?uselang=${i18n.language}`}
          >
            <div className={styles.ExternalLink}>
              <div>
                <Wikidata className="me-1" /> Wikidata
              </div>
              <div>
                <ArrowRight />
              </div>
            </div>
          </a>
          {monument?.article && (
            <a
              className="no-link"
              target={'_blank'}
              rel="noreferrer"
              href={monument.article}
            >
              <div className={styles.ExternalLink}>
                <div>
                  <Wikipedia className="me-1" />
                </div>
                <div>
                  <ArrowRight />
                </div>
              </div>
            </a>
          )}
          {username && (
            <div
              className={styles.ExternalLink}
              onClick={() => {
                if (monument?.in_contest && anyContest) {
                  setUploadWizard({
                    description: descriptionForWizard,
                    descriptionlang: activeGeocontext?.language_code,
                    categories: uploadCategories.join('|'),
                    campaign: `wlm-${activeGeocontext?.country_code}`,
                    id: monument?.wlm_n,
                  })
                } else {
                  setUploadWizard({
                    description: descriptionForWizard,
                    descriptionlang: activeGeocontext?.language_code,
                    categories: uploadCategories.join('|'),
                  })
                }
              }}
            >
              <div>
                <img
                  src={'/commonswiki.png'}
                  alt="Commons"
                  width={20}
                  className="me-1"
                />
                {t('wizard_upload_monumento')}
              </div>
              <div>
                <ArrowRight />
              </div>
            </div>
          )}
        </div>

        {username && user.admin_geo_contexts.includes(activeGeocontext?.id) && (
          <div className={styles.BlockEnableDisable}>
            <div className={styles.TitlePannelloAntivandalismo}>
              {t('ANTIVANDALISM_PANEL')}
            </div>
            <div className={styles.TextPannelloAntivandalismo}>
              {t('ANTIVANDALISM_PANEL_TEXT')}
            </div>
            <div className={'mt-3'}>
              {monument && (
                <>
                  {monument?.disabled ? (
                    <div>
                      <button
                        className={styles.ButtonEnable}
                        onClick={async () => {
                          await enableMonument(monument.id, token)
                          queryClient.invalidateQueries(
                            ['monument', monument.id],
                            { exact: true }
                          )
                          queryClient.invalidateQueries(['infiniteMonuments'])
                        }}
                      >
                        {t('ENABLE_MONUMENT')}
                      </button>
                    </div>
                  ) : (
                    <div>
                      <button
                        className={styles.ButtonDisable}
                        onClick={async () => {
                          await disableMonument(monument.id, token)
                          queryClient.invalidateQueries(
                            ['monument', monument.id],
                            { exact: true }
                          )
                          queryClient.invalidateQueries(['infiniteMonuments'])
                        }}
                      >
                        {t('DISABLE_MONUMENT')}
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
            {monument && <div className="mt-3">
              <div className={`${styles.TextPannelloAntivandalismo} mb-2`}>
              {t('UPDATE_PANEL_TEXT')}
            </div>
              <button
                className={updatingMonument ? styles.ButtonEnable : styles.ButtonDisable}
                disabled={updatingMonument}
                onClick={async () => {
                  setUpdateMonument(true)
                  await updateMonument(monument.id, token)
                  setUpdateMonument(false)
                  queryClient.invalidateQueries(['monument', monument.id], {
                    exact: true,
                  })
                  queryClient.invalidateQueries(['infiniteMonuments'])
                }}
              >
                <div className='d-flex flex-row align-items-center justify-content-center'>
                  <span className='me-2'>{t('UPDATE_MONUMENT')}</span>
                  {updatingMonument && <Spinner size="sm"></Spinner>}
                </div>
                
              </button>
            </div>}
          </div>
        )}
        <div className={styles.FixedButtonUpload}>
          <button
            onClick={() => {
              if (user) {
                inputFileRef.current?.click()
              } else {
                setShowLicenseModal(true)
              }
            }}
            className={styles.ButtonUpload}
          >
            <CameraDarkBig />
          </button>
          <input
            ref={inputFileRef}
            multiple
            max={2}
            onChange={(e) => {
              if (e.target.files && e.target.files?.length > 0) {
                if (e.target.files.length > 50) {
                  toast.error(t('YOU_CAN_UPLOAD_UP_TO_50_PHOTOS'))
                  return
                }
                setShowModalUpload(true)
                setImageUpload(e.target.files)
              }
            }}
            type="file"
            hidden={true}
            accept="image/*"
          />
        </div>
      </div>
      {showAllImages && monument && (
        <SlideShow
          infoSlideSlideShow={infoSlideSlideShow}
          setInfoSlideSlideShow={setInfoSlideSlideShow}
          setShowAllImages={setShowAllImages}
          setSlideShowActive={setSlideShowActive}
          slideShowActive={slideShowActive}
          monument={monument}
        />
      )}
      <BlockUploadFormik
        fileList={imageUpload}
        monument={monument}
        setUploadOpen={setShowModalUpload}
        uploadOpen={showModalUpload}
        setFileList={setImageUpload}
      />
      {showLicenseModal && (
        <div
          className={styles.ResponseOverlay}
          onClick={() => {
            setShowLicenseModal(false)
          }}
        >
          <div className={styles.ResponseContainer}>
            <div
              className="position-absolute pointer"
              style={{
                top: 20,
                right: 20,
              }}
            >
              <Close />
            </div>
            <div>
              <License />
            </div>
            <div className={styles.TextBeforeLogin}>
              {t('TO_LOGIN_YOU_MUST_ACCEPT_THE_FOLLOWING_CONDITIONS')}
            </div>
            <div className={styles.License}>
              <input
                checked
                className="me-2"
                type="checkbox"
                onChange={() => {}}
              />
              {t('I_ACCEPT_TO_SHARE_THE_IMAGES_WITH_THE_CC_BY_SA_4_0_LICENSE')}
            </div>
            <div className="w-100">
              <button
                className={styles.ButtonAccetta}
                // disabled
                onClick={() => {
                  localStorage.setItem('redirectUrl', pathname + search)
                  localStorage.setItem('monument', JSON.stringify(monument))

                  window.location.href = `${API_URL}/oauth/oauth-login?redirect_uri=${window.location.href}`
                }}
              >
                {t('ACCEPT')}
              </button>
            </div>
          </div>
        </div>
      )}
      {isMobile && appCategory?.is_municipality && (
        <VeduteInsiemeModal
          setVeduteInsiemeOpen={setVeduteInsiemeOpen}
          veduteInsiemeOpen={veduteInsiemeOpen}
        />
      )}
      <Modal
        centered
        show={!!uploadWizard}
        onHide={() => setUploadWizard(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('SWITCH_TO_UPLOADWIZARD')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{t('WIZARD_UPLOAD_TEXT')}</p>
        </Modal.Body>
        <Modal.Footer>
          <button
            className={styles.ButtonCancel}
            onClick={() => setUploadWizard(false)}
          >
            {t('CANCEL')}
          </button>
          <button
            className={styles.ButtonUpload}
            onClick={() => {
              const params = new URLSearchParams(uploadWizard as any)
              window.open(
                `https://commons.wikimedia.org/wiki/Special:UploadWizard?${params.toString()}`,
                '_blank'
              )
              setUploadWizard(false)
            }}
          >
            {t('CONTINUE')}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default function Detail({
  monumentId,
  setDetail,
  isDesktop = false,
}: Props) {
  const { slug } = useParams()
  const monument = slug
    ? useMonument(parseSmartSlug(slug!), isDesktop)
    : monumentId
    ? useMonument(monumentId, isDesktop)
    : null

  const activeGeocontext = getActiveGeocontext()

  const { checkDonationModal } = useDonationContext()

  useEffect(() => {
    checkDonationModal()
  }, [monumentId, slug])

  return isDesktop ? (
    <Suspense
      fallback={
        <div className="d-flex justify-content-center align-items-center h-100">
          <div className="loader" />
        </div>
      }
    >
      <DetailBlock
        monument={monument}
        isDesktop
        setDetail={setDetail}
        activeGeocontext={activeGeocontext}
      />
    </Suspense>
  ) : (
    <Layout>
      <Suspense
        fallback={
          <div className="d-flex justify-content-center align-items-center h-100">
            <div className="loader" />
          </div>
        }
      >
        <DetailBlock
          monument={monument}
          activeGeocontext={activeGeocontext}
          isDesktop={false}
        />
      </Suspense>
    </Layout>
  )
}
